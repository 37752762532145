import { Box } from 'grommet'
import React from 'react'
import NewDropShippingFulfillmentForm from '../../forms/dropShippingFulfillment/NewDropShippingFulfillmentForm'
import PageTitle from '../../utils/PageTitle'

function NewDropShippingFulfillment() {
  return (
    <Box>
      <PageTitle>New Drop Shipping Fulfillment</PageTitle>
      <NewDropShippingFulfillmentForm />
    </Box>
  )
}

export default NewDropShippingFulfillment
