import { Box, ResponsiveContext, Text } from 'grommet'
import React from 'react'

function PageTitle({ children, actionButton }: { children: React.ReactNode; actionButton?: React.ReactNode }) {
  const size = React.useContext(ResponsiveContext)
  return (
    <Box direction={size === 'xsmall' ? 'column' : 'row'} justify="between" margin={{ bottom: 'medium' }}>
      <Text size="xlarge" weight="bold">
        {children}
      </Text>
      <Text>{actionButton}</Text>
    </Box>
  )
}

PageTitle.defaultProps = {
  actionButton: null,
}

export default PageTitle
