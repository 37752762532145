import { Box, Text } from 'grommet'
import { useSelector } from 'react-redux'
import { selectSignedInRole } from '../../state/selectors/firestore'
import Loading from '../utils/Loading'
import PageTitle from '../utils/PageTitle'

function Home() {
  const role = useSelector(selectSignedInRole)

  let content
  if (role === 'vendor') {
    content = <Text size="large">Welcome to the ap0cene vendor portal</Text>
  } else if (role === 'admin') {
    content = <Text size="large">Welcome to the ap0cene admin portal</Text>
  } else {
    content = <Loading />
  }

  return (
    <Box>
      <PageTitle>Home</PageTitle>
      {content}
    </Box>
  )
}

export default Home
