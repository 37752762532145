import _ from 'lodash'
import { Box, Text } from 'grommet'
import React from 'react'
import { mapEnumToOptions } from '../autoform/utils'
import {
  AccessoriesSubtype,
  ActivewearSubtype,
  BagsSubtype,
  Color,
  DressesSubtype,
  Gender,
  IntimatesSwimSubtype,
  JewelrySubtype,
  OnePiecesSubtype,
  OuterwearSubtype,
  PantsSubtype,
  ProductType,
  ShoesSubtype,
  SkirtsSubtype,
  SuitSetSubtype,
  TopsSubtype,
} from '../../../lib/firestore/types/productEnums'

function mapIndexToOption(index: number, sizeOptionLength, colorOptionLength) {
  let sizeOptionIndex = 0
  let colorOptionIndex = 0
  while (sizeOptionIndex < sizeOptionLength) {
    while (colorOptionIndex < colorOptionLength) {
      if (sizeOptionIndex * colorOptionLength + colorOptionIndex === index) {
        return {
          sizeOptionIndex,
          colorOptionIndex,
        }
      }
      colorOptionIndex += 1
    }
    colorOptionIndex = 0
    sizeOptionIndex += 1
  }

  return {
    sizeOptionIndex: 0,
    colorOptionIndex: 0,
  }
}

const productTypesFormSchema = [
  {
    id: 'type',
    name: 'Product Type',
    type: 'select',
    options: mapEnumToOptions(ProductType),
    required: true,
  },
  {
    id: 'topsSubtype',
    name: 'Sub-Type, Tops',
    type: 'checkboxgroup',
    options: mapEnumToOptions(TopsSubtype),
    condition: (formState: any) => formState.type === 'Tops',
    required: true,
  },
  {
    id: 'dressesSubtype',
    name: 'Sub-Type, Dresses',
    type: 'checkboxgroup',
    options: mapEnumToOptions(DressesSubtype),
    condition: (formState: any) => formState.type === 'Dresses',
    required: true,
  },
  {
    id: 'onePiecesSubtype',
    name: 'Sub-Type, One-Pieces',
    type: 'checkboxgroup',
    options: mapEnumToOptions(OnePiecesSubtype),
    condition: (formState: any) => formState.type === 'One-Pieces',
    required: true,
  },
  {
    id: 'pantsSubtype',
    name: 'Sub-Type, Pants',
    type: 'checkboxgroup',
    options: mapEnumToOptions(PantsSubtype),
    condition: (formState: any) => formState.type === 'Pants',
    required: true,
  },
  {
    id: 'skirtsSubtype',
    name: 'Sub-Type, Skirts',
    type: 'checkboxgroup',
    options: mapEnumToOptions(SkirtsSubtype),
    condition: (formState: any) => formState.type === 'Skirts',
    required: true,
  },
  {
    id: 'intimatesSwimSubtype',
    name: 'Sub-Type, Intimates + Swim',
    type: 'checkboxgroup',
    options: mapEnumToOptions(IntimatesSwimSubtype),
    condition: (formState: any) => formState.type === 'Intimates + Swim',
    required: true,
  },
  {
    id: 'activewearSubtype',
    name: 'Sub-Type, Activewear',
    type: 'checkboxgroup',
    options: mapEnumToOptions(ActivewearSubtype),
    condition: (formState: any) => formState.type === 'Activewear',
    required: true,
  },
  {
    id: 'outerwearSubtype',
    name: 'Sub-Type, Outerwear',
    type: 'checkboxgroup',
    options: mapEnumToOptions(OuterwearSubtype),
    condition: (formState: any) => formState.type === 'Outerwear',
    required: true,
  },
  {
    id: 'bagsSubtype',
    name: 'Sub-Type, Bags',
    type: 'checkboxgroup',
    options: mapEnumToOptions(BagsSubtype),
    condition: (formState: any) => formState.type === 'Bags',
    required: true,
  },
  {
    id: 'accessoriesSubtype',
    name: 'Sub-Type, Accessories',
    type: 'checkboxgroup',
    options: mapEnumToOptions(AccessoriesSubtype),
    condition: (formState: any) => formState.type === 'Accessories',
    required: true,
  },
  {
    id: 'shoesSubtype',
    name: 'Sub-Type, Shoes',
    type: 'checkboxgroup',
    options: mapEnumToOptions(ShoesSubtype),
    condition: (formState: any) => formState.type === 'Shoes',
    required: true,
  },
  {
    id: 'jewlerySubtype',
    name: 'Sub-Type, Jewelry',
    type: 'checkboxgroup',
    options: mapEnumToOptions(JewelrySubtype),
    condition: (formState: any) => formState.type === 'Jewelry',
    required: true,
  },
  {
    id: 'suitSetSubtype',
    name: 'Is this a part of a suit or set?',
    type: 'checkboxgroup',
    options: mapEnumToOptions(SuitSetSubtype),
    condition: (formState: any) => {
      return _.includes(['Skirts', 'Pants', 'One-Pieces', 'Dresses', 'Tops', 'Outerwear'], formState.type)
    },
    required: false,
  },
]

const sizeOptionsFormSchema = {
  id: 'sizeOptions',
  // name: 'Size Option 1',
  type: 'compound',
  fields: [
    {
      id: 'name',
      name: 'Size',
      type: 'text',
      width: '20%',
      search: true,
      required: true,
      setGlobalValueOnChange: (value, id, formState, setFormState) => {
        const indexOfChangedValue = Number(id.split('.')[1])
        const { colorOptionCount, sizeOptionCount, colorOptions, sizeOptions } = formState
        const variantCount = colorOptionCount * sizeOptionCount
        const newVariants: any = []
        for (let i = 0; i < variantCount; i++) {
          const { sizeOptionIndex, colorOptionIndex } = mapIndexToOption(i, sizeOptionCount, colorOptionCount)
          const colorOptionName = colorOptions[colorOptionIndex]?.name
          const sizeOptionName = indexOfChangedValue === sizeOptionIndex ? value : sizeOptions[sizeOptionIndex]?.name
          newVariants.push({
            ...formState.variants[i],
            name: `${sizeOptionName} ${colorOptionName}`,
          })
        }
        const newFormState = {
          ..._.cloneDeep(formState),
          variants: newVariants,
        }
        _.set(newFormState, id, value)
        setFormState(newFormState)
      },
    },
    // {
    //   id: 'depth',
    //   type: 'number',
    //   name: 'Depth',
    //   condition: (formState: any) => {
    //     return _.includes(['Bags'], formState.type)
    //   },
    //   required: false,
    // },
    // {
    //   id: 'width',
    //   type: 'number',
    //   name: 'Width',
    //   condition: (formState: any) => {
    //     return _.includes(['Jewelry', 'Bags'], formState.type)
    //   },
    //   required: false,
    // },
    // {
    //   id: 'inseam',
    //   type: 'number',
    //   name: 'Inseam',
    //   condition: (formState: any) => {
    //     return _.includes(['One-Pieces', 'Pants', 'Intimates + Swim', 'Activewear'], formState.type)
    //   },
    //   required: false,
    // },
    // {
    //   id: 'sleeve',
    //   type: 'number',
    //   name: 'Sleeve',
    //   condition: (formState: any) => {
    //     return _.includes(
    //       ['Tops', 'Dresses', 'Intimates + Swim', 'One-Pieces', 'Outerwear', 'Activewear'],
    //       formState.type,
    //     )
    //   },
    //   required: false,
    // },
    // {
    //   id: 'chest',
    //   type: 'number',
    //   name: 'Chest',
    //   condition: (formState: any) => {
    //     return _.includes(
    //       ['Intimates + Swim', 'Tops', 'Dresses', 'One-Pieces', 'Outerwear', 'Activewear'],
    //       formState.type,
    //     )
    //   },
    //   required: false,
    // },
    // {
    //   id: 'waist',
    //   type: 'number',
    //   name: 'Waist',
    //   condition: (formState: any) => {
    //     return _.includes(
    //       ['Intimates + Swim', 'Tops', 'Dresses', 'One-Pieces', 'Outerwear', 'Activewear'],
    //       formState.type,
    //     )
    //   },
    //   required: false,
    // },
    // {
    //   id: 'hips',
    //   type: 'number',
    //   name: 'Hips',
    //   condition: (formState: any) => {
    //     return _.includes(
    //       ['Intimates + Swim', 'Dresses', 'One-Pieces', 'Outerwear', 'Activewear', 'Skirts', 'Pants', 'Shorts'],
    //       formState.type,
    //     )
    //   },
    //   required: false,
    // },
    // {
    //   id: 'shoulder',
    //   type: 'number',
    //   name: 'Shoulder',
    //   condition: (formState: any) => {
    //     return _.includes(['Tops', 'Dresses', 'One-Pieces', 'Activewear', 'Outerwear'], formState.type)
    //   },
    //   required: false,
    // },
    // {
    //   id: 'length',
    //   type: 'number',
    //   name: 'Length',
    //   condition: (formState: any) => {
    //     return _.includes(
    //       [
    //         'Tops',
    //         'One-Pieces',
    //         'Dresses',
    //         'Pants',
    //         'Skirts',
    //         'Intimates + Swim',
    //         'Outerwear',
    //         'Activewear',
    //         'Bags',
    //         'Shoes',
    //         'Jewelry',
    //         'Accessories',
    //       ],
    //       formState.type,
    //     )
    //   },
    //   required: false,
    // },
    {
      id: 'itemWeight',
      name: 'Weight (lbs)',
      type: 'number',
      search: true,
      required: false,
    },
  ],
}

const colorOptionFormSchema = {
  id: 'colorOptions',
  // name: 'Color Option 1',
  type: 'compound',
  fields: [
    {
      id: 'name',
      name: 'Color Family',
      type: 'select',
      options: mapEnumToOptions(Color),
      required: true,
      setGlobalValueOnChange: (value, id, formState, setFormState) => {
        const indexOfChangedValue = Number(id.split('.')[1])
        const { colorOptionCount, sizeOptionCount, colorOptions, sizeOptions } = formState
        const variantCount = colorOptionCount * sizeOptionCount
        const newVariants: any = []
        for (let i = 0; i < variantCount; i++) {
          const { sizeOptionIndex, colorOptionIndex } = mapIndexToOption(i, sizeOptionCount, colorOptionCount)
          const colorOptionName =
            indexOfChangedValue === colorOptionIndex ? value : colorOptions[colorOptionIndex]?.name
          const sizeOptionName = sizeOptions[sizeOptionIndex]?.name
          newVariants.push({
            ...formState.variants[i],
            name: `${sizeOptionName} ${colorOptionName}`,
          })
        }
        const newFormState = {
          ..._.cloneDeep(formState),
          variants: newVariants,
        }
        _.set(newFormState, id, value)
        setFormState(newFormState)
      },
    },
    {
      id: 'customColor',
      name: 'Custom Color Name',
      type: 'text',
      required: false,
      placeholder: 'ex: Navy Patchwork',
    },
    {
      id: 'material',
      name: 'Material',
      type: 'text',
      required: true,
      placeholder: 'ex: 64% Cotton, 36% Polyester',
    },
  ],
}

const variantsSchema = {
  id: 'variants',
  type: 'compound',
  fields: [
    {
      id: 'name',
      name: 'Name',
      type: 'text',
      disabled: true,
      default: (formState, id) => {
        const { colorOptionCount, sizeOptionCount, colorOptions, sizeOptions } = formState
        const index = Number(id.split('.')[1])
        const { sizeOptionIndex, colorOptionIndex } = mapIndexToOption(index, sizeOptionCount, colorOptionCount)
        const colorOptionName = colorOptions[colorOptionIndex]?.name || `[color ${colorOptionIndex}]`
        const sizeOptionName = sizeOptions[sizeOptionIndex]?.name || `[size ${sizeOptionIndex}]`
        return `${sizeOptionName} ${colorOptionName}`
      },
      required: true,
    },
    {
      id: 'quantity',
      name: 'Quantity',
      type: 'text',
      required: false,
      width: '70px',
    },
    // {
    //   id: 'images',
    //   name: 'Images',
    //   type: 'images',
    //   required: false,
    // },
  ],
}

export const productFormSchema = [
  {
    id: 'title',
    name: 'Title',
    type: 'text',
    required: true,
  },
  {
    id: 'images',
    name: 'Product Images',
    type: 'images',
    required: true,
  },
  {
    id: 'gender',
    name: 'Gender',
    type: 'checkboxgroup',
    options: mapEnumToOptions(Gender),
    required: true,
  },
  ...productTypesFormSchema,
  {
    id: 'copy',
    name: 'Product Description',
    type: 'textarea',
    required: true,
  },
  {
    formContent: (
      <Box>
        <Text size="small" margin={{ vertical: 'small' }}>
          Suggestions for What to Include in the Product Description:
          <ul>
            <li> Mention sustainability where applicable</li>
            <li> An in-depth look at the materials used</li>
            <li> Where and how it's made</li>
            <li> Any interesting facts or anecdotes</li>
            <li> Styling tips</li>
            <li> A good pun!</li>
          </ul>
          <i>We reserve the right to edit or clarify any product descriptions to fit our site formatting</i>
        </Text>
      </Box>
    ),
  },
  {
    id: `price`,
    name: `Price`,
    help: 'Price listed in USD',
    type: 'number',
  },
  // {
  //   formContent: (
  //     <Box>
  //       <Text size="xlarge">Variant Option Counts</Text>
  //     </Box>
  //   ),
  // },
  {
    id: `sizeOptionCount`,
    name: `Size Option Count`,
    type: 'counter',
  },
  {
    id: `colorOptionCount`,
    name: `Color Option Count`,
    type: 'counter',
  },
  {
    id: 'sizeOptions',
    name: 'Size Options',
    type: 'list',
    count: (formState) => formState.sizeOptionCount,
    field: sizeOptionsFormSchema,
  },
  {
    id: 'colorOptions',
    name: 'Color Options',
    type: 'list',
    count: (formState) => formState.colorOptionCount,
    field: colorOptionFormSchema,
  },
  {
    id: 'variants',
    name: 'Variants',
    type: 'list',
    count: (formState) => formState.colorOptionCount * formState.sizeOptionCount,
    field: variantsSchema,
  },
]

export const newProductFormDefaults = {
  title: '',
  images: [],
  gender: [],
  type: '',
  topsSubtype: [],
  dressesSubtype: [],
  onePiecesSubtype: [],
  pantsSubtype: [],
  skirtsSubtype: [],
  intimatesSwimSubtype: [],
  activewearSubtype: [],
  outerwearSubtype: [],
  bagsSubtype: [],
  accessoriesSubtype: [],
  shoesSubtype: [],
  jewlerySubtype: [],
  suitSetSubtype: [],
  copy: '',
  sizeOptionCount: 1,
  colorOptionCount: 1,
  price: 1,
  sizeOptions: [
    { name: '', depth: 0, width: 0, inseam: 0, height: 0, sleeve: 0, chest: 0, shoulder: 0, length: 0, itemWeight: 0 },
  ],
  colorOptions: [{ name: '', customColor: '', material: '' }],
  variants: [{ images: [], quantity: 0 }],
}
