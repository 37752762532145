import { createSelector, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

type UID = string

interface FirebaseAuthState {
  uid: UID
}

// Define the initial state using that type
const initialState: FirebaseAuthState = {
  uid: '',
}

export const firebaseAuthSlice = createSlice({
  name: 'firebaseAuth',
  initialState,
  reducers: {
    setUID: (state, action: PayloadAction<UID>) => {
      return {
        ...state,
        uid: action.payload,
      }
    },
  },
})

export const { setUID } = firebaseAuthSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectSignedInUID = (state: RootState) => state.firebaseAuth.uid

export const selectIsSignedIn = createSelector(selectSignedInUID, (uid) => uid !== '')

export default firebaseAuthSlice.reducer
