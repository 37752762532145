import { useSelector, shallowEqual } from 'react-redux'
import { selectSignedInRole } from '../../state/selectors/firestore'

const roleNeeded = process.env.REACT_APP_MODE

export function AuthorizationGate({ authorizedContent, unauthorizedContent }: any) {
  const userRole = useSelector(selectSignedInRole, shallowEqual)
  if (userRole !== roleNeeded) {
    return unauthorizedContent
  }
  return authorizedContent
}
