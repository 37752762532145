import React from 'react'
import { Box, Text } from 'grommet'
import { useNavigate, useParams } from 'react-router-dom'
import { Checkbox, CheckboxSelected } from 'grommet-icons'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import styled from 'styled-components'
import { selectOnboardingChecklists, selectSignedInVendor } from '../../../state/selectors/firestore'
import { isVendorMode } from '../../../utils'

function ViewOnboardingChecklistForm() {
  const navigate = useNavigate()
  const signedInVendor = useSelector(selectSignedInVendor, shallowEqual)
  const vendorId = useParams().id
  const selectedVendor = _.find(useSelector(selectOnboardingChecklists, shallowEqual), { id: vendorId })
  const completedVendorEthics = isVendorMode
    ? signedInVendor?.completedVendorEthics
    : selectedVendor?.completedVendorEthics
  const completedVendorContract = selectedVendor?.completedVendorContract
  const hasPaymentMethod = selectedVendor?.hasPaymentMethod
  const completedVendorProfileImage = selectedVendor?.completedVendorProfileImage
  const completedVendorProfile = selectedVendor?.completedVendorProfile
  const completedShippingProfile = selectedVendor?.completedShippingProfile

  const TextLink = styled(Text)`
    cursor: pointer;
  `
  const OnboardingTask = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `
  const CheckboxSelectedPadded = styled(CheckboxSelected)`
    padding-right: 0.5em;
  `

  const CheckboxPadded = styled(Checkbox)`
    padding-right: 0.5em;
  `

  return (
    <Box>
      <Text margin={{ vertical: 'medium' }} size="large">
        Step 1 - Becoming an Ap0cene Vendor
      </Text>
      {/* Will abstract out OnboardingTask component */}
      <OnboardingTask>
        {completedVendorEthics ? <CheckboxSelectedPadded /> : <CheckboxPadded />}
        <TextLink margin={{ vertical: 'small' }} size="small" onClick={() => navigate(`/vendorEthics`)}>
          Read through our <Text weight="bold">Vendor Ethics</Text>
        </TextLink>
      </OnboardingTask>
      <OnboardingTask>
        {completedVendorContract ? <CheckboxSelectedPadded /> : <CheckboxPadded />}
        <TextLink margin={{ vertical: 'small' }} size="small" onClick={() => navigate(`/vendors/${vendorId}`)}>
          Sign the Ap0cene <Text weight="bold">Marketplace Vendor Contract</Text>
        </TextLink>
      </OnboardingTask>
      <Text margin={{ vertical: 'medium' }} size="large">
        Step 2 - Set Up Payouts
      </Text>
      <OnboardingTask>
        {hasPaymentMethod ? <CheckboxSelectedPadded /> : <CheckboxPadded />}
        <TextLink margin={{ vertical: 'small' }} size="small" onClick={() => navigate(`/paymentMethods/new`)}>
          Register with the <Text weight="bold">Payment Portal</Text>
        </TextLink>
      </OnboardingTask>
      <Text margin={{ vertical: 'medium' }} size="large">
        Step 3 - Vendor Profile
      </Text>
      <OnboardingTask>
        {completedVendorProfileImage ? <CheckboxSelectedPadded /> : <CheckboxPadded />}
        <Text margin={{ vertical: 'small' }} size="small">
          Gather a profile image and some production process content, using our{' '}
          <Text weight="bold">Vendor Media Guide</Text> as a reference
        </Text>
      </OnboardingTask>
      <OnboardingTask>
        {completedVendorProfile ? <CheckboxSelectedPadded /> : <CheckboxPadded />}
        <TextLink margin={{ vertical: 'small' }} size="small" onClick={() => navigate(`/vendors/${vendorId}`)}>
          Complete your <Text weight="bold">Vendor Profile</Text>
        </TextLink>
      </OnboardingTask>
      <OnboardingTask>
        {completedShippingProfile ? <CheckboxSelectedPadded /> : <CheckboxPadded />}
        <TextLink margin={{ vertical: 'small' }} size="small" onClick={() => navigate(`/shippingProfiles/${vendorId}`)}>
          Fill out your <Text weight="bold">Shipping Profile</Text> to generate shipping rates
        </TextLink>
      </OnboardingTask>
      <Text margin={{ vertical: 'medium' }} size="large">
        Step 4 - Add Products
      </Text>
      <OnboardingTask>
        {false ? <CheckboxSelectedPadded /> : <CheckboxPadded />}
        <Text margin={{ vertical: 'small' }} size="small">
          Take clear, consistent, high-quality product images following our{' '}
          <Text weight="bold">Product Photography Guide</Text>
        </Text>
      </OnboardingTask>
      <OnboardingTask>
        {false ? <CheckboxSelectedPadded /> : <CheckboxPadded />}
        <TextLink margin={{ vertical: 'small' }} size="small" onClick={() => navigate(`/products`)}>
          Begin adding your first poducts on the <Text weight="bold">Products</Text> page
        </TextLink>
      </OnboardingTask>
      <Text margin={{ vertical: 'medium' }} size="large">
        Step 5 - Ship
      </Text>
      <OnboardingTask>
        {false ? <CheckboxSelectedPadded /> : <CheckboxPadded />}
        <Text margin={{ vertical: 'small' }} size="small">
          You are now read to ship your products!
        </Text>
      </OnboardingTask>
      <Text margin={{ vertical: 'small' }} size="small">
        For Wholesale + Consignment Products - please package and send your stock to and Ap0cene fulfillment center
        using the <Text weight="bold">Shipments</Text> page
      </Text>
      <Text margin={{ vertical: 'small' }} size="small">
        For Made-to-Order Products - please package and send your samlpes to an Ap0cene fulfillment center using the
        <Text weight="bold">Shipments</Text> page; please check the <Text weight="bold">Customer Orders</Text> page and
        keep an eye on your email for incoming orders you will need to fulfill
      </Text>
    </Box>
  )
}

export default ViewOnboardingChecklistForm
