export enum Collection {
  shippingProfiles = 'vendorShippingRates',
  vendors = 'vendors',
  paymentMethods = 'paymentMethods',
  dropShipments = 'dropShipments',
  products = 'products',
  shopifyFulfillments = 'shopifyFulfillments',
  vendorEthics = 'vendorEthics',
  vendorRoles = 'vendorRoles',
}
