import React from 'react'
import { Box } from 'grommet'
import { addDoc, collection } from 'firebase/firestore'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { db } from '../../../firebase'
import AutoForm from '../autoform/AutoForm'
import { selectShopifyFulfillmentsById, selectVendors } from '../../../state/selectors/firestore'
import { Vendor } from '../../../lib/firestore/types/types'
import { dropShippingFulfillmentFormSchema, initialDropShippingFulfillmentValues } from './constants'
import { Collection } from '../../../lib/firestore/collections'
import { cleanObject } from '../autoform/utils'
import joi from '../../../lib/firestore/joi'
import ReadOnlyFulfillmentRequest from './ReadOnlyFulfillmentRequest'
// import {format} from "date-fns";

function NewDropShippingFulfillmentForm() {
  const navigate = useNavigate()
  const vendors: Vendor[] = useSelector(selectVendors)
  const params = useParams()
  const shopifyFulfillmentId: any = params.id
  const shopifyFulfillmentsById = useSelector(selectShopifyFulfillmentsById)

  const shopifyFulfillment = shopifyFulfillmentsById[shopifyFulfillmentId]
  if (!shopifyFulfillment) {
    return null
  }

  const initialVendor = vendors.find((v) => v.id === shopifyFulfillment.vendorId)

  const onSubmit = async ({
    orderNumber,
    trackingNumber,
    courier,
    trackingUrl,
    dateShipped,
    costOfShipping,
    receiptImage,
  }: any) => {
    if (!initialVendor) {
      throw new Error('Vendor not found')
    }

    const fields = cleanObject({
      orderNumber,
      receiptImage,
      vendorId: initialVendor.id,
      vendorName: initialVendor.brandName,
      vendorSkuPrefix: initialVendor.skuPrefix,
      trackingNumber,
      courier,
      trackingUrl,
      dateShipped,
      costOfShipping,
      fulfillmentId: shopifyFulfillment.id,
      submissionDate: new Date().toISOString(),
    })
    const result = joi.dropShipment.validate(fields)
    if (result.error) {
      throw new Error(result.error.message)
    }
    await addDoc(collection(db, Collection.dropShipments), fields)
    navigate(`/dropShippingFulfillments`)
  }

  return (
    <Box>
      <ReadOnlyFulfillmentRequest fulfillmentRequest={shopifyFulfillment} />
      <AutoForm
        initialValues={{
          ...initialDropShippingFulfillmentValues,
          orderNumber: shopifyFulfillment.orderNumber,
          vendorName: shopifyFulfillment.vendorName,
        }}
        readOnlyMode={false}
        formSchema={dropShippingFulfillmentFormSchema}
        onSubmit={onSubmit}
        onCancel={() => navigate('/dropShippingFulfillments')}
      />
    </Box>
  )
}

export default NewDropShippingFulfillmentForm
