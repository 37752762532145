import { Heading } from 'grommet'
import {
  BankAccountOwner,
  bankAccountOwnerLabels,
  BankAccountType,
  bankAccountTypeLabels,
  PaymentMethod,
  PreferredPaymentMethod,
  preferredPaymentMethodLabels,
} from '../../../lib/firestore/types/types'
import { mapEnumAndLabelsToOptions } from '../autoform/utils'

export const paymentMethodFormSchema = [
  {
    type: 'text',
    id: 'recipientName',
    name: "Recipient's Name",
    required: true,
  },
  {
    type: 'select',
    id: 'method',
    name: 'Preferred Payment Method',
    options: mapEnumAndLabelsToOptions(PreferredPaymentMethod, preferredPaymentMethodLabels),
    required: true,
  },
  // ACH FIELDS
  {
    formContent: <Heading level={4}>Domestic ACH Transfer</Heading>,
    condition: (values: PaymentMethod) => values.method === PreferredPaymentMethod.ach,
  },
  {
    type: 'address',
    id: 'achAddress',
    name: "Recipient's US Address",
    required: true,
    condition: (values: PaymentMethod) => values.method === PreferredPaymentMethod.ach,
  },
  {
    type: 'select',
    id: 'achAccountType',
    name: 'Account Type',
    options: mapEnumAndLabelsToOptions(BankAccountType, bankAccountTypeLabels),
    required: true,
    condition: (values: PaymentMethod) => values.method === PreferredPaymentMethod.ach,
  },
  {
    type: 'select',
    id: 'achAccountOwner',
    name: 'Account Owner',
    options: mapEnumAndLabelsToOptions(BankAccountOwner, bankAccountOwnerLabels),
    required: true,
    condition: (values: PaymentMethod) => values.method === PreferredPaymentMethod.ach,
  },
  {
    type: 'text',
    id: 'achRoutingNumber',
    name: 'Routing Number',
    required: true,
    condition: (values: PaymentMethod) => values.method === PreferredPaymentMethod.ach,
  },
  {
    type: 'text',
    id: 'achAccountNumber',
    name: 'Account Number',
    required: true,
    condition: (values: PaymentMethod) => values.method === PreferredPaymentMethod.ach,
  },
  // WIRE FIELDS
  {
    formContent: <Heading level={4}>International Wire Transfer</Heading>,
    condition: (values: PaymentMethod) => values.method === PreferredPaymentMethod.wire,
  },
  {
    type: 'address',
    id: 'wireAddress',
    name: "Recipient's Address",
    required: true,
    condition: (values: PaymentMethod) => values.method === PreferredPaymentMethod.wire,
  },
  {
    type: 'text',
    id: 'wireBankName',
    name: 'Bank Name',
    required: true,
    condition: (values: PaymentMethod) => values.method === PreferredPaymentMethod.wire,
  },
  {
    type: 'select',
    id: 'wireAccountType',
    name: 'Account Type',
    options: mapEnumAndLabelsToOptions(BankAccountType, bankAccountTypeLabels),
    required: true,
    condition: (values: PaymentMethod) => values.method === PreferredPaymentMethod.wire,
  },
  {
    type: 'select',
    id: 'wireAccountOwner',
    name: 'Account Owner',
    options: mapEnumAndLabelsToOptions(BankAccountOwner, bankAccountOwnerLabels),
    required: true,
    condition: (values: PaymentMethod) => values.method === PreferredPaymentMethod.wire,
  },
  {
    type: 'text',
    id: 'swiftCode',
    name: 'SWIFT/BIC Code',
    required: true,
    condition: (values: PaymentMethod) => values.method === PreferredPaymentMethod.wire,
  },
  {
    type: 'checkbox',
    id: 'ibanNotRequired',
    name: 'I am in a country that does not require an IBAN number',
    required: false,
    condition: (values: PaymentMethod) => values.method === PreferredPaymentMethod.wire,
  },
  {
    type: 'text',
    id: 'iban',
    name: 'IBAN',
    required: true,
    condition: (values) => {
      return values.method === PreferredPaymentMethod.wire && !values.ibanNotRequired
    },
  },
  {
    type: 'text',
    id: 'wireAccountNumber',
    name: 'Account Number',
    required: true,
    condition: (values) => values.method === PreferredPaymentMethod.wire && values.ibanNotRequired,
  },
  {
    id: 'countrySpecificFields',
    name: 'Country Specific Fields',
    type: 'compound',
    condition: (values: PaymentMethod) => values.method === PreferredPaymentMethod.wire,
    fields: [
      {
        type: 'text',
        id: 'purposeCode',
        name: 'Purpose Code',
        required: false,
      },
      {
        type: 'text',
        id: 'branchCode',
        name: 'Branch Code',
        required: false,
      },
    ],
  },
  // PAYPAL FIELDS
  {
    formContent: <Heading level={4}>PayPal</Heading>,
    condition: (values: PaymentMethod) => values.method === PreferredPaymentMethod.paypal,
  },
  {
    type: 'email',
    id: 'paypal',
    name: 'PayPal Email Address',
    required: true,
    condition: (values: PaymentMethod) => values.method === PreferredPaymentMethod.paypal,
  },
  // verify
  {
    type: 'checkbox',
    id: 'verified',
    name: 'I have verified that the above account information is correct',
    required: true,
    condition: (values: PaymentMethod) => !!values.method,
    validate: (val) => {
      if (!val) {
        return 'You must verify that the above account information is correct'
      }
    },
  },
]

export const initialPaymentMethodValues = {
  recipientName: '',
  method: '',
  verified: false,
  vendorId: '',
  vendorName: '',
  vendorUid: '',
}
