import { Box } from 'grommet'
import React from 'react'
import ViewShippingProfileForm from '../../forms/shippingProfile/ViewShippingProfileForm'
import PageTitle from '../../utils/PageTitle'

function ShippingProfile() {
  return (
    <Box>
      <PageTitle>Shipping Profile</PageTitle>
      <ViewShippingProfileForm />
    </Box>
  )
}

export default ShippingProfile
