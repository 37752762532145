import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { firebase } from '../../firebase'
import { selectIsSignedIn, setUID } from '../../state/reducers/firebaseAuth'

export function SignInGate({ signedInContent, signedOutContent }: any) {
  const dispatch = useDispatch()
  const isSignedIn = useSelector(selectIsSignedIn, shallowEqual)
  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        dispatch(setUID(user.uid))
      }
    })
    return () => unregisterAuthObserver() // Make sure we un-register Firebase observers when the component unmounts.
  }, [])

  if (isSignedIn) {
    return signedInContent
  }
  return signedOutContent
}
