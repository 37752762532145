import React, { useState } from 'react'
import { Box, Button } from 'grommet'
import styled from 'styled-components'
import {
  bindInitialValuesToSchema,
  createInitialErrorsObject,
  createIntialValuesObject,
  evaluateValidators,
  generateValidators,
  isEmptyNestedObject,
  populateCompoundFieldsOnFormSchema,
} from './utils'
import { renderField } from './fieldRenderer'

const RenderedFormContainer = styled(Box)`
  flex-flow: wrap;
`

export const renderFormElements = (
  formSchema: any,
  formState: any,
  setFormState: any,
  readOnlyMode: boolean,
  errors: any,
  setFormErrors: any,
  validators: any,
) => {
  const fieldMap = formSchema.map((field: any) =>
    renderField(field, formState, setFormState, readOnlyMode, errors, setFormErrors, validators),
  )

  return <RenderedFormContainer>{fieldMap}</RenderedFormContainer>
}

// function getInitialStateKeys(fields: any) {
//   return fields.reduce((agg: Array<any>, field: any) => {
//     if (field.subform) {
//       const subValues = getInitialStateKeys(field.subform)
//       return [...agg, ...subValues]
//     }
//     return [...agg, field.id]
//   }, [])
// }
//
// function getInitialStateValues(fields: any) {
//   return fields.reduce((agg: Array<any>, field: any) => {
//     if (field.subform) {
//       const subValues = getInitialStateValues(field.subform)
//       return [...agg, ...subValues]
//     }
//     return [...agg, field.initialState]
//   }, [])
// }

function AutoForm({
  formSchema: initialFormSchema,
  initialValues,
  onSubmit,
  readOnlyMode,
  onCancel,
}: {
  formSchema: Array<any>
  initialValues: Object
  onSubmit?: any
  readOnlyMode: boolean
  onCancel?: any
}) {
  const formSchemaWithCompoundFields = populateCompoundFieldsOnFormSchema(initialFormSchema)
  const formSchema = bindInitialValuesToSchema(formSchemaWithCompoundFields, initialValues)
  // @ts-ignore
  const initialFormState = createIntialValuesObject(formSchema)

  const [formState, setFormState] = useState(initialFormState)
  const [errors, setFormErrors] = useState(createInitialErrorsObject(formSchema, formState))

  const validators = generateValidators(formSchema, formState)
  const [submitting, setSubmitting] = useState(false)

  const submitForm = async () => {
    const formErrors = evaluateValidators(validators, formState)
    if (!isEmptyNestedObject(formErrors)) {
      setFormErrors(formErrors)
      return
    }
    setSubmitting(true)
    try {
      await onSubmit(formState)
    } catch (e) {
      console.error(e)
      setSubmitting(false)
    }
    setSubmitting(false)
  }

  return (
    <Box margin={{ top: 'medium' }}>
      {renderFormElements(formSchema, formState, setFormState, readOnlyMode, errors, setFormErrors, validators)}
      {readOnlyMode ? null : (
        <Box margin={{ top: 'medium' }} direction="row" justify="around">
          {onSubmit ? <Button primary onClick={() => submitForm()} label="Submit" disabled={submitting} /> : null}
          {onCancel ? (
            <Button
              secondary
              type="button"
              label="Cancel"
              disabled={submitting}
              onClick={() => {
                setFormState(initialFormState)
                onCancel()
              }}
            />
          ) : null}
        </Box>
      )}
    </Box>
  )
}

AutoForm.defaultProps = {
  onSubmit: null,
  onCancel: null,
}

export default AutoForm
