import { Text, Box, DataTable } from 'grommet'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectOnboardingChecklists } from '../../../state/selectors/firestore'
import PageTitle from '../../utils/PageTitle'

function OnboardingChecklists() {
  const onboardingChecklists = useSelector(selectOnboardingChecklists)

  const navigate = useNavigate()

  const VendorOnboardingTable = (
    <DataTable
      sortable
      onClickRow={(e: any) => {
        navigate(`/onboardingChecklists/${e?.datum?.id}`)
      }}
      columns={[
        {
          property: 'vendorName',
          header: <Text weight="bold">Brand Name</Text>,
          primary: true,
          search: true,
          sortable: true,
        },
        {
          property: 'skuPrefix',
          header: 'SKU',
          search: true,
          sortable: true,
        },
        {
          property: 'vendorStatus',
          header: <Text>Status</Text>,
          primary: true,
          search: true,
          sortable: true,
        },
      ]}
      data={onboardingChecklists}
    />
  )

  return (
    <Box>
      <PageTitle>Vendor Onboarding</PageTitle>
      {VendorOnboardingTable}
    </Box>
  )
}

export default OnboardingChecklists
