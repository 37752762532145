import _ from 'lodash'
import { Anchor, Box, Button, Text } from 'grommet'
import { Add, Subtract } from 'grommet-icons'
import React from 'react'
import { NoneValue, ReadOnlyBox, ReadOnlyTitle } from '../ViewComponents'
import AutoFormField from '../AutoFormField'

function CounterField({
  id,
  name,
  type,
  formState,
  required,
  help,
  validate,
  disabled,
  readOnlyMode,
  readOnlyFormattedValue,
  width,
  errors,
  setFormState,
}: any) {
  const value = _.get(formState, id) || 1
  const isSubField = _.includes(id, '.')

  if (readOnlyMode) {
    if (isSubField) {
      return (
        <Box key={id} margin={{ right: 'medium' }}>
          <Text>{_.get(formState, id) || <NoneValue />}</Text>
          <Text size="xsmall">{name}</Text>
        </Box>
      )
    }

    return (
      <ReadOnlyBox key={id} width="100%">
        <ReadOnlyTitle>{name}</ReadOnlyTitle>
        {type === 'url' ? (
          <Anchor
            href={value}
            label={readOnlyFormattedValue}
            target="_blank"
            reverse
            onClick={(e) => e.stopPropagation()}
            weight="normal"
          />
        ) : (
          <Text>{readOnlyFormattedValue}</Text>
        )}
      </ReadOnlyBox>
    )
  }

  const handleIncrement = () => {
    const newValue = value + 1
    setFormState((prevState: any) => ({
      ...prevState,
      [id]: newValue,
    }))
  }

  const handleDecrement = () => {
    const newValue = value > 1 ? value - 1 : 1
    setFormState((prevState: any) => ({
      ...prevState,
      [id]: newValue,
    }))
  }

  return (
    <AutoFormField
      id={id}
      key={id}
      name={name}
      required={required}
      help={help}
      validate={validate}
      disabled={disabled}
      width={width}
      error={_.get(errors, id)}
    >
      <Box direction="row" gap="small">
        <Button onClick={handleDecrement}>
          <Subtract size="small" />
        </Button>
        <Text>{value}</Text>
        <Button onClick={handleIncrement}>
          <Add size="small" />
        </Button>
      </Box>
    </AutoFormField>
  )
}

export default CounterField
