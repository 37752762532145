import _ from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import short from 'short-uuid'
import { useSelector } from 'react-redux'
import { Box, Text } from 'grommet'
import { doc, setDoc } from 'firebase/firestore'
import React from 'react'
import { db } from '../../../firebase'
import { editVendorFormSchema } from './constants'
import AutoForm from '../autoform/AutoForm'
import { selectVendorsById } from '../../../state/selectors/firestore'
import { Collection } from '../../../lib/firestore/collections'

function EditVendorAccountForm() {
  const params = useParams()
  const navigate = useNavigate()

  const vendorId = params.id
  if (!vendorId) {
    return null
  }

  const vendorsById = useSelector(selectVendorsById)
  const vendor = vendorsById[vendorId]

  if (!vendor) {
    return <Text>Vendor ID Does not exist</Text>
  }

  const onSubmit = async ({
    brandName,
    email,
    uid,
    commissionPercentage,
    dropShippingAllowed,
    contractType,
    contract,
    contractAddendum,
    paymentMethod,
    status,
    tier,
    id,
    initialPassword,
    skuPrefix,
  }: any) => {
    const fields = _.pickBy(
      {
        brandName,
        email,
        uid,
        commissionPercentage,
        dropShippingAllowed,
        contractType,
        contract,
        contractAddendum,
        paymentMethod,
        tier,
        status,
        initialPassword,
        skuPrefix,
      },
      _.identity,
    )

    const mergedFields = {
      ...vendor,
      ...fields,
    }

    await setDoc(doc(db, Collection.vendors, id), mergedFields)
    navigate(`/vendors/${id}`)
  }

  return (
    <Box>
      <AutoForm
        initialValues={{
          ...vendor,
          initialPassword: vendor.initialPassword || short.generate(),
        }}
        formSchema={editVendorFormSchema}
        onSubmit={onSubmit}
        onCancel={() => navigate(`/vendors/${vendorId}`)}
        readOnlyMode={false}
      />
    </Box>
  )
}

export default EditVendorAccountForm
