import React, { useContext, useEffect, useState } from 'react'
import { DropButton, Box, Text, ResponsiveContext } from 'grommet'
import styled from 'styled-components'
import { Logout } from 'grommet-icons'
import { firebase } from '../../firebase'

const DropDownBox = styled(Box)`
  cursor: pointer;
`

const StyledLogout = styled(Logout)`
  vertical-align: middle;
`

function SignOutButton() {
  const [isSignedIn, setIsSignedIn] = useState(false) // Local signed-in state.

  const size = useContext(ResponsiveContext)
  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      setIsSignedIn(!!user)
    })
    return () => unregisterAuthObserver() // Make sure we un-register Firebase observers when the component unmounts.
  }, [])

  const displayName = size === 'xsmall' ? <StyledLogout /> : firebase.auth().currentUser?.displayName

  const content = (
    <DropDownBox
      pad="small"
      onClick={() => {
        firebase.auth().signOut()
        window.location.reload()
      }}
    >
      <Text>Sign Out</Text>
    </DropDownBox>
  )

  if (!isSignedIn) {
    return null
  }

  return (
    <DropButton
      label={displayName}
      dropAlign={{
        top: 'bottom',
      }}
      // margin={{
      //   right: 'medium',
      // }}
      dropContent={content}
    />
  )
}

export default SignOutButton
