// import { City, Country, State } from 'country-state-city'
// import _ from 'lodash'

// const countries = Country.getAllCountries()
// const states = State.getAllStates()
// const cities = City.getAllCities()
// const countryOptions = _.uniq(_.map(countries, 'name')).sort()
// const stateOptions = _.uniq(_.map(states, 'name')).sort()
// const cityOptions = _.uniq(_.map(cities, 'name')).sort()

export const addressFields = [
  {
    id: 'addressLine1',
    name: 'Street Address',
    required: true,
    type: 'text',
  },
  {
    id: 'addressLine2',
    name: 'Street Address Line 2',
    required: false,
    type: 'text',
  },
  // {
  //   id: 'city',
  //   name: 'City',
  //   required: true,
  //   type: 'select',
  //   search: true,
  //   options: cityOptions,
  // },
  {
    id: 'city',
    name: 'City',
    required: true,
    type: 'text',
  },
  // {
  //   id: 'state',
  //   name: 'State / Province',
  //   required: true,
  //   type: 'select',
  //   search: true,
  //   options: stateOptions,
  // },
  {
    id: 'state',
    name: 'State / Province',
    required: true,
    type: 'text',
  },
  {
    id: 'zip',
    name: 'Postal / Zip Code',
    required: true,
    type: 'text',
  },
  // {
  //   id: 'country',
  //   required: true,
  //   type: 'select',
  //   search: true,
  //   options: countryOptions,
  // },
  {
    id: 'country',
    required: true,
    type: 'text',
    name: 'Country',
  },
]
