import { Accordion, AccordionPanel, Box, Text } from 'grommet'
import React from 'react'

function CurrencyConverter() {
  return (
    <Box margin={{ top: 'medium' }} border width="50%" alignSelf="center">
      <Accordion animate multiple={false}>
        <AccordionPanel
          label={
            <Text size="xsmall" margin="xsmall">
              Currency Conversion Tool
            </Text>
          }
        >
          <Box align="center">
            <iframe
              style={{
                border: 'none',
                height: '307px',
                width: '412px',
              }}
              title="Currency Converter"
              src="https://cloudonshorecom.fatcow.com/arielwidget.html"
            />
          </Box>
        </AccordionPanel>
      </Accordion>
    </Box>
  )
}

export default CurrencyConverter
