import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

interface UIState {
  isSidebarOpen: boolean
}

// Define the initial state using that type
const initialState: UIState = {
  isSidebarOpen: false,
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
      return {
        isSidebarOpen: action.payload,
      }
    },
  },
})

export const { setIsSidebarOpen } = uiSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectIsSidebarOpen = (state: RootState) => state.ui.isSidebarOpen

export default uiSlice.reducer
