import { Text, Box } from 'grommet'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import React from 'react'
import ViewVendorAccountForm from '../../forms/vendorAccount/ViewVendorAccountForm'
import ViewVendorProfileForm from '../../forms/vendorProfile/ViewVendorProfileForm'
import { selectVendorsById } from '../../../state/selectors/firestore'
import PageTitle from '../../utils/PageTitle'

function Vendor() {
  const params = useParams()

  const vendorId = params.id
  if (!vendorId) {
    return null
  }

  const vendorsById = useSelector(selectVendorsById)
  const vendor = vendorsById[vendorId]

  if (!vendor) {
    return <Text>Vendor ID Does not exist</Text>
  }

  return (
    <Box>
      <PageTitle>{vendor.brandName}</PageTitle>
      <Text margin={{ vertical: 'medium' }} size="xlarge">
        Account Settings
      </Text>
      <ViewVendorAccountForm />
      <Text margin={{ vertical: 'medium' }} size="xlarge">
        Profile
      </Text>
      <ViewVendorProfileForm />
    </Box>
  )
}

export default Vendor
