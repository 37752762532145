import { Box } from 'grommet'
// import OldProductForm from '../../forms/product/deprecatedProductStuff/OldProductForm'
import PageTitle from '../../utils/PageTitle'
import ViewProductForm from '../../forms/product/ViewProductForm'

function Product() {
  return (
    <Box>
      <PageTitle>Product</PageTitle>
      <ViewProductForm />
    </Box>
  )
}

export default Product
