import Joi from 'joi'
import { BankAccountOwner, BankAccountType, PreferredPaymentMethod, VendorStatus, VendorTier } from './types/types'

/*
 *  ADDRESS SCHEMA
 */

const addressSchema = Joi.object().keys({
  addressLine1: Joi.string().required(),
  addressLine2: Joi.string().optional().allow(''),
  city: Joi.string().required(),
  state: Joi.string().required(),
  zip: Joi.string().required(),
  country: Joi.string().required(),
})

/*
 *  SHIPPING PROFILE SCHEMA
 */

const shippingProfile = Joi.object().keys({
  id: Joi.string().optional(),
  australia: Joi.number().required(),
  canada: Joi.number().required(),
  europeFlatRate: Joi.number().when('isAllEurope', { is: true, then: Joi.required(), otherwise: Joi.optional() }),
  france: Joi.number().when('isAllEurope', { is: false, then: Joi.required(), otherwise: Joi.optional() }),
  germany: Joi.number().when('isAllEurope', { is: false, then: Joi.required(), otherwise: Joi.optional() }),
  intnlFlatRate: Joi.number().required(),
  italy: Joi.number().when('isAllEurope', { is: false, then: Joi.required(), otherwise: Joi.optional() }),
  netherlands: Joi.number().when('isAllEurope', { is: false, then: Joi.required(), otherwise: Joi.optional() }),
  newZealand: Joi.number().required(),
  southKorea: Joi.number().required(),
  uk: Joi.number().required(),
  usa: Joi.number().required(),
  vendorName: Joi.string().required(),
  vendorSkuPrefix: Joi.string().required(),
  vendorId: Joi.string().required(),
  isAllEurope: Joi.boolean().required(),
})

/*
 *  PAYMENT METHOD SCHEMA
 */

const achPaymentMethod = Joi.object().keys({
  address: addressSchema.required(),
  accountType: Joi.string()
    .valid(...Object.values(BankAccountType))
    .required(),
  accountOwner: Joi.string()
    .valid(...Object.values(BankAccountOwner))
    .required(),
  routingNumber: Joi.string().required(),
  accountNumber: Joi.string().required(),
})

const wirePaymentMethod = Joi.object().keys({
  address: addressSchema.required(),
  bankName: Joi.string().required(),
  accountType: Joi.string()
    .valid(...Object.values(BankAccountType))
    .required(),
  accountOwner: Joi.string()
    .valid(...Object.values(BankAccountOwner))
    .required(),
  swiftCode: Joi.string().required(),
  iban: Joi.string().optional(),
  accountNumber: Joi.string().optional(),
  ibanNotRequired: Joi.boolean().required(),
  purposeCode: Joi.string().optional(),
  branchCode: Joi.string().optional(),
})

const paymentMethod = Joi.object().keys({
  id: Joi.string().optional(),
  recipientName: Joi.string().required(),
  method: Joi.string()
    .valid(...Object.values(PreferredPaymentMethod))
    .required(),
  ach: achPaymentMethod.when('method', { is: PreferredPaymentMethod.ach, then: Joi.required() }),
  wire: wirePaymentMethod.when('method', { is: PreferredPaymentMethod.wire, then: Joi.required() }),
  paypal: Joi.string().when('method', { is: PreferredPaymentMethod.paypal, then: Joi.required() }),
  verified: Joi.boolean().required(),
  vendorId: Joi.string().required(),
  vendorName: Joi.string().required(),
  vendorUid: Joi.string().required(),
})

/*
 *  DROP SHIPMENT SCHEMA
 */

const dropShipment = Joi.object({
  id: Joi.string().optional(),
  costOfShipping: Joi.number().required(),
  courier: Joi.string().required(),
  dateShipped: Joi.string().isoDate().required(),
  orderNumber: Joi.number().required(),
  receiptImage: Joi.array().items(Joi.string()).required(),
  submissionDate: Joi.string().isoDate().required(),
  trackingNumber: Joi.string().required(),
  trackingUrl: Joi.string().optional(),
  vendorId: Joi.string().required(),
  vendorName: Joi.string().required(),
  vendorSkuPrefix: Joi.string().required(),
  fulfillmentId: Joi.number().required(),
})

/*
 *  VENDOR SCHEMAS
 */

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

const vendorSchema = Joi.object({
  brandName: Joi.string().required(),
  commissionPercentage: Joi.number().required(),
  completedVendorEthics: Joi.boolean().required(),
  contract: Joi.array().items(Joi.string()).required(),
  contractAddendum: Joi.array().items(Joi.string()).optional(),
  contractType: Joi.array().items(Joi.string()).optional(),
  dropShippingAllowed: Joi.string().valid('allowed', 'notAllowed').required(),
  email: Joi.string().required().email({ tlds: false }).regex(emailRegex).required(),
  id: Joi.string().required(),
  initialPassword: Joi.string().required(),
  skuPrefix: Joi.string().required(),
  status: Joi.string()
    .valid(
      VendorStatus.active,
      VendorStatus.activeSoldOut,
      VendorStatus.offboarded,
      VendorStatus.offboarding,
      VendorStatus.onPause,
      VendorStatus.onboarding,
      VendorStatus.retired,
      VendorStatus.retiring,
    )
    .required(),
  tier: Joi.string().valid(VendorTier.cirrus, VendorTier.cumulus, VendorTier.nimbus, VendorTier.stratus).required(),
  uid: Joi.string().required(),
})

const vendorProfileSchema = Joi.object({
  actionList: Joi.array().items(Joi.string()).required(),
  basedIn: Joi.object({
    city: Joi.string().required(),
    country: Joi.string().required(),
    state: Joi.string().required(),
  }).required(),
  brandDescription: Joi.string().required(),
  brandProfileImage: Joi.string().required(),
  contactEmail: Joi.string().email({ tlds: false }).regex(emailRegex).required(),
  contactLocation: Joi.object({
    city: Joi.string().required(),
    country: Joi.string().required(),
  }).required(),
  contactPhoneNumber: Joi.string().required(),
  mailingAddress: addressSchema.required(),
  pointOfContact: Joi.object({
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
  }).required(),
  pronouns: Joi.array().items(Joi.string()).required(),
  socialMedia: Joi.object({
    instagram: Joi.string().required(),
    tiktok: Joi.string().required(),
    twitter: Joi.string().required(),
  }).required(),
  website: Joi.string().required(),
})

/*
 *  PRODUCT SCHEMAS
 */

const productSchema = Joi.object({
  id: Joi.string(),
  title: Joi.string().required(),
  images: Joi.array().items(Joi.string()).required(),
  vendorId: Joi.string().required(),
  gender: Joi.array().items(Joi.string()).required(),
  type: Joi.string().required(),
  topsSubtype: Joi.array().items(Joi.string()).required(),
  dressesSubtype: Joi.array().items(Joi.string()).required(),
  onePiecesSubtype: Joi.array().items(Joi.string()).required(),
  pantsSubtype: Joi.array().items(Joi.string()).required(),
  skirtsSubtype: Joi.array().items(Joi.string()).required(),
  intimatesSwimSubtype: Joi.array().items(Joi.string()).required(),
  activewearSubtype: Joi.array().items(Joi.string()).required(),
  outerwearSubtype: Joi.array().items(Joi.string()).required(),
  bagsSubtype: Joi.array().items(Joi.string()).required(),
  accessoriesSubtype: Joi.array().items(Joi.string()).required(),
  shoesSubtype: Joi.array().items(Joi.string()).required(),
  jewlerySubtype: Joi.array().items(Joi.string()).required(),
  suitSetSubtype: Joi.array().items(Joi.string()).required(),
  copy: Joi.string().required(),
  sizeOptionCount: Joi.number().required(),
  colorOptionCount: Joi.number().required(),
  price: Joi.number().required(),
  sizeOptions: Joi.array().items(
    Joi.object({
      name: Joi.string().required(),
      depth: Joi.number().optional(),
      width: Joi.number().optional(),
      inseam: Joi.number().optional(),
      height: Joi.number().optional(),
      sleeve: Joi.number().optional(),
      chest: Joi.number().optional(),
      shoulder: Joi.number().optional(),
      length: Joi.number().optional(),
      itemWeight: Joi.number().required(),
    }),
  ),
  colorOptions: Joi.array().items(
    Joi.object({
      name: Joi.string().required(),
      customColor: Joi.string().optional().allow(''),
      material: Joi.string().required(),
    }),
  ),
  variants: Joi.array().items(
    Joi.object({
      name: Joi.string().required(),
      images: Joi.array().items(Joi.string()).optional(),
      quantity: Joi.number(),
    }),
  ),
})

const joi = {
  paymentMethod,
  shippingProfile,
  dropShipment,
  vendorSchema,
  vendorProfileSchema,
  productSchema,
}

export default joi
