import React from 'react'
import _ from 'lodash'
import { Box } from 'grommet'
import { doc, setDoc } from 'firebase/firestore'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { db } from '../../../firebase'
import AutoForm from '../autoform/AutoForm'
import { selectDropShipmentsById, selectVendors } from '../../../state/selectors/firestore'
import { Vendor } from '../../../lib/firestore/types/types'
import { dropShippingFulfillmentFormSchema } from './constants'
import { Collection } from '../../../lib/firestore/collections'
import joi from '../../../lib/firestore/joi'
// import {format} from "date-fns";

function EditDropShippingFulfillmentForm() {
  const params = useParams()
  const dropShippingFulfillmentId: any = params.id
  const navigate = useNavigate()
  const vendors: Vendor[] = useSelector(selectVendors)
  const dropShippingFulfillmentsById = useSelector(selectDropShipmentsById)
  const dropShippingFulfillment = dropShippingFulfillmentsById[dropShippingFulfillmentId]

  if (!dropShippingFulfillment) {
    return null
  }

  const initialVendor = vendors.find((v: Vendor) => v.id === dropShippingFulfillment.vendorId)

  if (!initialVendor) {
    throw new Error('Vendor Not Found')
  }

  const formSchema = [
    ...dropShippingFulfillmentFormSchema,
    {
      id: 'submissionDate',
      name: 'Date Submitted',
      type: 'date',
      required: true,
    },
  ]

  const onSubmit = async ({
    orderNumber,
    trackingNumber,
    courier,
    trackingUrl,
    dateShipped,
    costOfShipping,
    receiptImage,
    submissionDate,
  }: any) => {
    const fields = _.pickBy({
      orderNumber,
      receiptImage,
      vendorId: initialVendor.id,
      vendorName: initialVendor.brandName,
      vendorSkuPrefix: initialVendor.skuPrefix,
      trackingNumber,
      courier,
      trackingUrl,
      dateShipped,
      costOfShipping,
      submissionDate,
    })
    const result = joi.dropShipment.validate(fields)
    if (result.error) {
      throw new Error(result.error.message)
    }
    await setDoc(doc(db, Collection.dropShipments, dropShippingFulfillment.id), fields)
    navigate(`/dropShippingFulfillments`)
  }

  return (
    <Box margin={{ top: 'medium' }}>
      <AutoForm
        initialValues={{
          ...dropShippingFulfillment,
          vendorName: initialVendor?.brandName,
        }}
        readOnlyMode={false}
        formSchema={formSchema}
        onSubmit={onSubmit}
        onCancel={() => navigate('/dropShippingFulfillments')}
      />
    </Box>
  )
}

export default EditDropShippingFulfillmentForm
