import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Box, Button, Text } from 'grommet'
import React from 'react'
import { editVendorProfileFormSchema } from './constants'
import AutoForm from '../autoform/AutoForm'
import { selectVendorsById } from '../../../state/selectors/firestore'

function ViewVendorAccountForm() {
  const params = useParams()
  const navigate = useNavigate()

  const vendorId = params.id
  if (!vendorId) {
    return null
  }

  const vendorsById = useSelector(selectVendorsById)
  const vendor = vendorsById[vendorId]

  if (!vendor) {
    return <Text>Vendor ID Does not exist</Text>
  }
  return (
    <Box>
      <AutoForm formSchema={editVendorProfileFormSchema} initialValues={vendor} readOnlyMode />
      <Button secondary label="Edit" onClick={() => navigate(`/vendorProfiles/${vendorId}/edit`)} />
    </Box>
  )
}

export default ViewVendorAccountForm
