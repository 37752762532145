import { Box } from 'grommet'
import React from 'react'
import NewPaymentMethodForm from '../../forms/paymentMethod/NewPaymentMethodForm'
import PageTitle from '../../utils/PageTitle'

function NewPaymentMethod() {
  return (
    <Box>
      <PageTitle>New Payment Method</PageTitle>
      <NewPaymentMethodForm />
    </Box>
  )
}

export default NewPaymentMethod
