// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { getStorage } from 'firebase/storage'
import { getFirestore } from 'firebase/firestore'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAjKoUhAaUGhJjGLigX0lcgiR8bSvNwe44',
  authDomain: 'marketplace-353300.firebaseapp.com',
  projectId: 'marketplace-353300',
  storageBucket: 'marketplace-353300.appspot.com',
  messagingSenderId: '340150284703',
  appId: '1:340150284703:web:02b66155b00c5b04e50daa',
  measurementId: 'G-529VGE66M1',
}

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig)
const storage = getStorage(app)
const db = getFirestore(app)

export { app, firebase, storage, db }
