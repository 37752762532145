import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Box } from 'grommet'
import { collection, addDoc } from 'firebase/firestore'
import _ from 'lodash'
import React from 'react'
import { db } from '../../../firebase'
import { addShippingRateFormSchema } from './constants'
import AutoForm from '../autoform/AutoForm'
import { selectSignedInRole, selectSignedInVendor, selectVendors } from '../../../state/selectors/firestore'
import joi from '../../../lib/firestore/joi'
import { Collection } from '../../../lib/firestore/collections'
import { cleanObject } from '../autoform/utils'

function NewShippingProfileForm() {
  const navigate = useNavigate()

  const vendors = useSelector(selectVendors)
  const vendorBrandNames = vendors.map((vendor) => vendor.brandName)
  const role = useSelector(selectSignedInRole)
  const signedInVendor = useSelector(selectSignedInVendor)

  const onSubmit = async ({
    vendorName,
    australia,
    canada,
    europeFlatRate,
    france,
    germany,
    intnlFlatRate,
    italy,
    netherlands,
    newZealand,
    southKorea,
    uk,
    usa,
    isAllEurope,
  }: any) => {
    const vendor = _.find(vendors, { brandName: vendorName })
    const vendorSkuPrefix = vendor?.skuPrefix
    const vendorId = vendor?.id

    const fields = cleanObject({
      vendorName,
      vendorSkuPrefix,
      vendorId,
      australia,
      canada,
      europeFlatRate,
      france,
      germany,
      intnlFlatRate,
      italy,
      netherlands,
      newZealand,
      southKorea,
      uk,
      usa,
      isAllEurope,
    })
    const result = joi.shippingProfile.validate(fields)
    if (result.error) {
      throw new Error(result.error.message)
    }
    const ref = await addDoc(collection(db, Collection.shippingProfiles), fields)
    navigate(`/shippingProfiles/${ref.id}`)
  }

  const dynamicSchema = [
    {
      id: 'vendorName',
      name: 'Vendor',
      type: role === 'vendor' ? 'text' : 'select',
      options: vendorBrandNames,
      search: true,
      required: true,
      disabled: role === 'vendor',
    },
    ...addShippingRateFormSchema,
  ]

  return (
    <Box>
      <AutoForm
        initialValues={{
          vendorName: role === 'vendor' ? signedInVendor?.brandName : undefined,
        }}
        formSchema={dynamicSchema}
        readOnlyMode={false}
        onSubmit={onSubmit}
        onCancel={() => navigate(`/shippingProfiles`)}
      />
    </Box>
  )
}

export default NewShippingProfileForm
