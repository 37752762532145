import { Box, Text } from 'grommet'
import styled from 'styled-components'
import React from 'react'
import { Routes, Route } from 'react-router-dom'
import LeftNav from './LeftNav'
import Vendors from './pages/vendor/Vendors'
import { AuthorizationGate } from './authorization/AuthorizationGate'
import Products from './pages/product/Products'
import Product from './pages/product/Product'
import DropShippingFulfillments from './pages/dropShippingFulfillment/DropShippingFulfillments'
import DropShippingFulfillment from './pages/dropShippingFulfillment/DropShippingFulfillment'
import Vendor from './pages/vendor/Vendor'
import EditVendorAccount from './pages/vendor/account/EditVendorAccount'
import EditVendorProfile from './pages/vendor/profile/EditVendorProfile'
import NewVendorAccount from './pages/vendor/account/NewVendorAccount'
import ShippingProfiles from './pages/shippingProfile/ShippingProfiles'
import NewShippingProfile from './pages/shippingProfile/NewShippingProfile'
import ShippingProfile from './pages/shippingProfile/ShippingProfile'
import EditShippingProfile from './pages/shippingProfile/EditShippingProfile'
import NewDropShippingFulfillment from './pages/dropShippingFulfillment/NewDropShippingFulfillment'
import EditDropShippingFulfillment from './pages/dropShippingFulfillment/EditDropShippingFulfillment'
import Home from './pages/Home'
import PaymentMethods from './pages/paymentMethod/PaymentMethods'
import NewPaymentMethod from './pages/paymentMethod/NewPaymentMethod'
import PaymentMethod from './pages/paymentMethod/PaymentMethod'
import EditPaymentMethod from './pages/paymentMethod/EditPaymentMethod'
import OnboardingChecklists from './pages/onboardingChecklist/OnboardingChecklists'
import OnboardingChecklist from './pages/onboardingChecklist/OnboardingChecklist'
import ViewVendorEthicsForm from './forms/onboardingChecklist/ViewVendorEthicsForm'
import NewProduct from './pages/product/NewProduct'
import EditProduct from './pages/product/EditProduct'

const BodyContainer = styled(Box)`
  align-items: center;
`

const BodyBox = styled(Box)`
  height: 100%;
  max-width: 1080px;
  width: 100%;
  //border: 1px solid black;
`

const Container = styled(Box)`
  position: relative;
  flex: 1;
`

const FullScreenBox = styled(Box)`
  width: 100%;
  z-index: 0;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 60px;
  left: 0;
`

function NavBody() {
  const unauthorizedContent = (
    <FullScreenBox>
      <Text>You do not have permission to view this site</Text>
    </FullScreenBox>
  )

  const authorizedContent = (
    <BodyContainer>
      <BodyBox direction="row">
        <LeftNav />
        <Container margin="large">
          <Routes>
            <Route path="/" element={<Home />} />
            {/* vendor routes */}
            <Route path="/vendors/:id" element={<Vendor />} />
            <Route path="/vendors" element={<Vendors />} />
            <Route path="/vendorAccounts/:id/edit" element={<EditVendorAccount />} />
            <Route path="/vendorAccounts/new" element={<NewVendorAccount />} />
            <Route path="/vendorProfiles/:id/edit" element={<EditVendorProfile />} />
            {/* product routes */}
            <Route path="/products" element={<Products />} />
            <Route path="/products/new" element={<NewProduct />} />
            <Route path="/products/:id" element={<Product />} />
            <Route path="/products/:id/edit" element={<EditProduct />} />
            {/* drop shipping routes */}
            <Route path="/dropShippingFulfillments" element={<DropShippingFulfillments />} />
            <Route path="/dropShippingFulfillments/new/:id" element={<NewDropShippingFulfillment />} />
            <Route path="/dropShippingFulfillments/:id" element={<DropShippingFulfillment />} />
            <Route path="/dropShippingFulfillments/:id/edit" element={<EditDropShippingFulfillment />} />
            {/* shipping rates routes */}
            <Route path="/shippingProfiles" element={<ShippingProfiles />} />
            <Route path="/shippingProfiles/new" element={<NewShippingProfile />} />
            <Route path="/shippingProfiles/:id" element={<ShippingProfile />} />
            <Route path="/shippingProfiles/:id/edit" element={<EditShippingProfile />} />
            {/* payment methods */}
            <Route path="/paymentMethods" element={<PaymentMethods />} />
            <Route path="/paymentMethods/new" element={<NewPaymentMethod />} />
            <Route path="/paymentMethods/:id" element={<PaymentMethod />} />
            <Route path="/paymentMethods/:id/edit" element={<EditPaymentMethod />} />
            {/* onboarding checklists */}
            <Route path="/onboardingChecklists" element={<OnboardingChecklists />} />
            <Route path="/onboardingChecklists/:id" element={<OnboardingChecklist />} />
            <Route path="/vendorEthics" element={<ViewVendorEthicsForm />} />
          </Routes>
        </Container>
      </BodyBox>
    </BodyContainer>
  )

  return <AuthorizationGate authorizedContent={authorizedContent} unauthorizedContent={unauthorizedContent} />
}

export default NavBody
