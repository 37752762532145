export const vendorEthicsFormSchema = [
  {
    id: 'vendorEthics',
    name: 'I have read and understand the Vendor Ethics',
    type: 'checkbox',
    required: false,
  },
]
export const initialValuesVendorEthicsForm = {
  vendorEthics: false,
}
