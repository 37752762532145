import { Text, Box, DataTable, Button } from 'grommet'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  dropShippingAllowedLabels,
  vendorContractTypeLabels,
  vendorStatusLabels,
  Vendor,
} from '../../../lib/firestore/types/types'
import { selectVendors } from '../../../state/selectors/firestore'
import PageTitle from '../../utils/PageTitle'

const TableBox = styled(Box)`
  width: 100%;
  overflow-x: auto;
`

function Vendors() {
  const vendors = useSelector(selectVendors)
  const navigate = useNavigate()

  const VendorTable = (
    <DataTable
      sortable
      onClickRow={(e: any) => {
        navigate(`/vendors/${e?.datum?.id}`)
      }}
      columns={[
        {
          property: 'brandName',
          header: <Text weight="bold">Brand Name</Text>,
          primary: true,
          search: true,
          sortable: true,
        },
        {
          property: 'contractType',
          header: 'Type',
          search: true,
          sortable: true,
          render: (datum: Vendor) => (
            <Text>
              {Array.isArray(datum.contractType)
                ? datum.contractType.map((type) => vendorContractTypeLabels[type]).join(', ')
                : ''}
            </Text>
          ),
        },
        {
          property: 'dropShippingAllowed',
          header: 'Drop Shipping',
          sortable: true,
          render: (datum: Vendor) => <Text>{dropShippingAllowedLabels[datum.dropShippingAllowed]}</Text>,
        },
        {
          property: 'status',
          header: 'Status',
          search: true,
          sortable: true,
          render: (datum: Vendor) => <Text>{vendorStatusLabels[datum.status]}</Text>,
        },
      ]}
      data={vendors}
    />
  )

  const actionButton = <Button secondary label="Add Vendor" onClick={() => navigate('/vendorAccounts/new')} />

  return (
    <Box>
      <PageTitle actionButton={actionButton}>Vendors</PageTitle>
      <TableBox>{VendorTable}</TableBox>
    </Box>
  )
}

export default Vendors
