import { Text, Select, Box } from 'grommet'
import React from 'react'
import _ from 'lodash'
import { NoneValue, ReadOnlyBox, ReadOnlyTitle } from '../ViewComponents'
import AutoFormField from '../AutoFormField'

class SelectField extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    const { options } = this.props
    // Don't call this.setState() here!
    this.state = { filteredOptions: options }
  }

  validateOnBlur = () => {
    const { id, validators, setFormErrors, formState, errors } = this.props
    const validator = _.get(validators, id)
    const value = _.get(formState, id, '')
    const error = validator(value)
    const newFormErrors = _.set(_.cloneDeep(errors), id, error)
    setFormErrors(newFormErrors)
  }

  onSearch = (text) => {
    const { options } = this.props
    // The line below escapes regular expression special characters:
    // [ \ ^ $ . | ? * + ( )
    const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')

    // Create the regular expression with modified value which
    // handles escaping special characters. Without escaping special
    // characters, errors will appear in the console
    const exp = new RegExp(escapedText, 'i')
    if (_.isObject(_.first(options))) {
      this.setState({ filteredOptions: options.filter((o: any) => exp.test(o.label)) })
    } else {
      this.setState({ filteredOptions: options.filter((o: any) => exp.test(o)) })
    }
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const {
      id,
      name,
      placeholder,
      transformFn,
      formState,
      required,
      help,
      validate,
      disabled,
      setFormState,
      readOnlyMode,
      width,
      options,
      search,
      errors,
      setGlobalValueOnChange,
    } = this.props
    const { filteredOptions } = this.state
    const isObjectOptions = _.isObject(_.first(options))

    const isSubField = _.includes(id, '.')

    if (readOnlyMode) {
      const readOnlyValue = isObjectOptions
        ? options?.find((option) => option.value === _.get(formState, id))?.label
        : _.get(formState, id)

      if (isSubField) {
        return (
          <Box key={id} margin={{ right: 'medium' }}>
            <Text>{readOnlyValue || <NoneValue />}</Text>
            <Text size="xsmall">{name}</Text>
          </Box>
        )
      }

      return (
        <ReadOnlyBox key={id} width={width || '100%'}>
          <ReadOnlyTitle>{name}</ReadOnlyTitle>
          <Text>{readOnlyValue}</Text>
        </ReadOnlyBox>
      )
    }

    return (
      <AutoFormField
        id={id}
        key={id}
        name={name}
        required={required}
        help={help}
        validate={validate}
        disabled={disabled}
        error={_.get(errors, id)}
        width={width}
      >
        <Select
          name={id}
          placeholder={placeholder}
          disabled={disabled}
          value={_.get(formState, id)}
          options={filteredOptions}
          valueKey={isObjectOptions ? { key: 'value', reduce: true } : undefined}
          labelKey={isObjectOptions ? 'label' : undefined}
          onChange={(e: any) => {
            const val = transformFn(e.value)
            const newFormState = _.set(_.cloneDeep(formState), id, val)
            setFormState(newFormState)
            if (setGlobalValueOnChange) {
              setGlobalValueOnChange(val, id, formState, setFormState)
            }
            this.validateOnBlur()
          }}
          onSearch={search ? this.onSearch : undefined}
          onBlur={this.validateOnBlur}
        />
      </AutoFormField>
    )
  }
}

export default SelectField
