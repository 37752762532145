import { Box, Text } from 'grommet'
import React from 'react'
import CurrencyConverter from '../utils/CurrencyConverter'

const approvedCarriers = [
  // United States Postal Service
  'USPS',
  // FedEx Corporation, an American multinational courier delivery service
  'FedEx',
  // United Parcel Service, an American multinational package delivery and supply chain management company
  'UPS',
  // DHL International GmbH, a German international courier, parcel, and express mail service
  'DHL',
  // National postal operator in Russia
  'Russian Post',
  // National postal operator in Armenia
  'Armenian Post',
  // International express postal service offered by postal-administration members of the Universal Postal Union
  'EMS',
  // La Poste, national postal service in France
  'La Poste',
  // National postal operator in Lithuania
  'Lithuanian Post',
  // Spanish parcel delivery and courier company
  'Zeleris',
  // National postal operator in Australia
  'Australia Post',
  // National postal operator in Belgium
  'Belgian Post',
  // A delivery and logistics company
  'Evri',
  // Deutsche Post, national postal operator in Germany
  'German Post Office',
  // Cross-border logistics and shipping provider in Asia
  'Janio',
  // National postal operator in Georgia
  'Georgian Post',
  // Correos, national postal operator in Spain
  'Spanish Post',
  // A global shipping and mailing service provider, joint venture of French La Poste and Swiss Post
  'Asendia',
  // National postal service in the United Kingdom
  'Royal Mail',
  // International parcel delivery service based in Europe
  'DPD',
  // National postal operator in Canada
  'Canada Post',
  // Canadian shipping service for e-commerce businesses
  'Chit Chats',
  // APC Postal Logistics, a shipping and mailing service provider
  'APC',
  // National postal operator in Switzerland
  'Swiss Post',
  // General Logistics Systems, a Dutch, British-owned logistics company
  'GLS',
  // National postal operator in South Korea
  'Korea Post',
  // National postal operator in Mexico
  'Mexican Post',
  // British courier and parcel delivery service
  'Yodel',
  'Hay Post',
  'Landmark Global',
  'Chrono Post',
  'Posten',
  'Post Italiane',
  'Correos',
  'Japan Post',
]

export const dropShippingFulfillmentFormSchema = [
  {
    id: 'vendorName',
    name: 'Vendor',
    type: 'text',
    required: true,
    disabled: true,
  },
  {
    id: 'orderNumber',
    name: 'Order Number',
    type: 'text',
    required: true,
    disabled: true,
  },
  {
    id: 'trackingNumber',
    name: 'Tracking Number',
    type: 'text',
    required: true,
  },
  {
    id: 'courier',
    name: 'Courier',
    type: 'select',
    options: approvedCarriers,
    search: true,
    required: true,
  },
  {
    id: 'trackingUrl',
    name: 'Tracking URL',
    type: 'url',
    required: false,
  },
  {
    id: 'dateShipped',
    name: 'Date Shipped',
    type: 'date',
    required: true,
  },
  {
    id: 'costOfShipping',
    name: 'Cost of Shipping',
    type: 'currency',
    required: true,
  },
  {
    formContent: (
      <Box margin="medium">
        <Text textAlign="center" size="small">
          Amount entered <i>MUST</i> match the amount listed on the receipt, use the currency conversion tool below to
          convert to USD
          <br />
          <br />
          You will only be reimbursed for the amount paid
        </Text>
      </Box>
    ),
  },
  {
    formContent: <CurrencyConverter />,
  },
  {
    id: 'receiptImage',
    name: 'Receipt Image',
    type: 'images',
    required: true,
  },
]

export const initialDropShippingFulfillmentValues = {
  vendorBrandName: '',
  orderNumber: '',
  trackingNumber: '',
  courier: '',
  trackingURL: '',
  dateShipped: '',
  costOfShipping: '',
  receiptImage: '',
}
