import { Anchor } from 'grommet'
import _ from 'lodash'
import React from 'react'
import { NoneValue, ReadOnlyBox, ReadOnlyTitle } from '../../ViewComponents'
import { PDFsBox, PDFBox } from './ViewComponents'

function ReadOnlyPDFsField({
  formState,
  id,
  name,
  width,
}: {
  formState: any
  id: string
  name: string
  width: string
}) {
  const value = _.get(formState, id, [])
  return (
    <ReadOnlyBox key={id} width={width || '100%'}>
      <ReadOnlyTitle>{name}</ReadOnlyTitle>
      {_.isEmpty(value) ? <NoneValue /> : null}
      <PDFsBox readOnly>
        {_.values(
          _.map(value, (fileURL: any, fileURLIndex) => {
            return (
              <PDFBox
                key={fileURL}
                round="xsmall"
                pad={{ horizontal: 'small', vertical: 'xsmall' }}
                overflow="hidden"
                border={{ color: 'dark-2' }}
                direction="row"
                alignContent="center"
              >
                <Anchor href={fileURL} target="_blank">
                  {value.length > 1 ? `${name} ${fileURLIndex + 1}.pdf` : `${name}.pdf`}
                </Anchor>
              </PDFBox>
            )
          }),
        )}
      </PDFsBox>
    </ReadOnlyBox>
  )
}

export default ReadOnlyPDFsField
