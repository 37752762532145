import { Box, Text } from 'grommet'
import _ from 'lodash'
import styled from 'styled-components'
import { FieldError } from './ViewComponents'
// import styled from 'styled-components'

export const AutoFormFieldContainer = styled(Box)`
  margin-top: 10px;
  margin-bottom: 10px;
  //background-color: #f9f9f9;
  padding-left: 10px;
  padding-right: 10px;
  //width: 100%;
`

export const AutoFormSubFieldContainer = styled(Box)`
  margin-top: 10px;
  margin-bottom: 10px;
  //background-color: #f9f9f9;
  //width: 100%;
`

export const RequiredAstrix = styled(Text)`
  color: red;
`

function AutoFormField({ id, name, help, children, width, required, error }: any) {
  const isSubField = _.includes(id, '.')

  if (isSubField) {
    return (
      <AutoFormSubFieldContainer direction="column" margin={{ bottom: 'medium' }} width={width || '100%'}>
        <Box>{children}</Box>
        <FieldError>{error}</FieldError>
        <Text size="xsmall">
          {help || name} <RequiredAstrix>{required ? '*' : ''}</RequiredAstrix>
        </Text>
      </AutoFormSubFieldContainer>
    )
  }

  const helpField = help ? (
    <Text size="xsmall" margin={{ top: '2px' }}>
      {help}
    </Text>
  ) : null

  return (
    <AutoFormFieldContainer direction="column" margin={{ bottom: 'medium' }} width={width || '100%'}>
      <Text size="large">
        {name} <RequiredAstrix>{required ? '*' : ''}</RequiredAstrix>
      </Text>
      <Box>{children}</Box>
      <FieldError>{error}</FieldError>
      {helpField}
    </AutoFormFieldContainer>
  )
}

export default AutoFormField
