import { cleanObject } from '../autoform/utils'
import { PaymentMethod } from '../../../lib/firestore/types/types'

export function mapPaymentMethodFormFieldsToFirestoreFields(formFields, vendors) {
  const {
    vendorName,
    recipientName,
    method,
    achAddress,
    achAccountType,
    achAccountOwner,
    achRoutingNumber,
    achAccountNumber,
    wireAddress,
    wireBankName,
    wireAccountType,
    wireAccountOwner,
    swiftCode,
    ibanNotRequired,
    iban,
    wireAccountNumber,
    purposeCode,
    branchCode,
    paypal,
    verified,
  } = formFields
  const vendor: any = vendors.find((v: { brandName: string }) => v.brandName === vendorName)

  const achFields =
    method === 'ach'
      ? {
          address: achAddress,
          accountType: achAccountType,
          accountOwner: achAccountOwner,
          routingNumber: achRoutingNumber,
          accountNumber: achAccountNumber,
        }
      : undefined

  const wireFields =
    method === 'wire'
      ? {
          address: wireAddress,
          bankName: wireBankName,
          accountType: wireAccountType,
          accountOwner: wireAccountOwner,
          swiftCode,
          ibanNotRequired,
          iban,
          accountNumber: wireAccountNumber,
          purposeCode,
          branchCode,
        }
      : undefined

  const firestoreFields: PaymentMethod = cleanObject({
    vendorId: vendor.id,
    vendorName: vendor.brandName,
    vendorUid: vendor.uid,
    recipientName,
    method,
    ach: achFields,
    wire: wireFields,
    paypal,
    verified,
  })
  return firestoreFields
}

export function mapFirestoreFieldsToPaymentMethodFormFields(firestoreFields) {
  const { vendorName, recipientName, method, ach, wire, paypal, verified } = firestoreFields

  const achFields =
    method === 'ach' && ach
      ? {
          achAddress: ach.address,
          achAccountType: ach.accountType,
          achAccountOwner: ach.accountOwner,
          achRoutingNumber: ach.routingNumber,
          achAccountNumber: ach.accountNumber,
        }
      : {}

  const wireFields =
    method === 'wire' && wire
      ? {
          wireAddress: wire.address,
          wireBankName: wire.bankName,
          wireAccountType: wire.accountType,
          wireAccountOwner: wire.accountOwner,
          swiftCode: wire.swiftCode,
          ibanNotRequired: wire.ibanNotRequired,
          iban: wire.iban,
          wireAccountNumber: wire.accountNumber,
          purposeCode: wire.purposeCode,
          branchCode: wire.branchCode,
        }
      : {}

  const formFields = {
    vendorName,
    recipientName,
    method,
    paypal,
    verified,
    ...achFields,
    ...wireFields,
  }

  return formFields
}
