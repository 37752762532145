import { useNavigate, useParams } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { Box, Button, Text } from 'grommet'
import React from 'react'
import { editVendorFormSchema } from './constants'
import AutoForm from '../autoform/AutoForm'
import { selectSignedInRole, selectVendorsById } from '../../../state/selectors/firestore'

function ViewVendorAccountForm() {
  const params = useParams()
  const navigate = useNavigate()

  const role = useSelector(selectSignedInRole, shallowEqual)
  const vendorId = params.id
  if (!vendorId) {
    return null
  }

  const vendorsById = useSelector(selectVendorsById)
  const vendor = vendorsById[vendorId]

  if (!vendor) {
    return <Text>Vendor ID Does not exist</Text>
  }
  return (
    <Box>
      <AutoForm initialValues={vendor} formSchema={editVendorFormSchema} readOnlyMode />
      {role === 'admin' && (
        <Button secondary label="Edit" onClick={() => navigate(`/vendorAccounts/${vendorId}/edit`)} />
      )}
    </Box>
  )
}

export default ViewVendorAccountForm
