import { Box } from 'grommet'
import React from 'react'
import EditDropShippingFulfillmentForm from '../../forms/dropShippingFulfillment/EditDropShippingFulfillmentForm'
import PageTitle from '../../utils/PageTitle'

function EditDropShippingFulfillment() {
  return (
    <Box>
      <PageTitle>Edit Drop Shipping Fulfillment</PageTitle>
      <EditDropShippingFulfillmentForm />
    </Box>
  )
}

export default EditDropShippingFulfillment
