import { Box } from 'grommet'
import React from 'react'
import ViewPaymentMethodForm from '../../forms/paymentMethod/ViewPaymentMethodForm'
import PageTitle from '../../utils/PageTitle'

function PaymentMethod() {
  return (
    <Box>
      <PageTitle>Payment Method</PageTitle>
      <ViewPaymentMethodForm />
    </Box>
  )
}

export default PaymentMethod
