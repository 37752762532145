import { configureStore } from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-cycle
import firestoreReducer from './reducers/firestore'
import firebaseAuthReducer from './reducers/firebaseAuth'
import uiReducer from './reducers/ui'
import firestoreMiddleware from './middleware/firestore'

export const store = configureStore({
  reducer: {
    firestore: firestoreReducer,
    firebaseAuth: firebaseAuthReducer,
    ui: uiReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(firestoreMiddleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
