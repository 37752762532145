import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Anchor, Box, Text } from 'grommet'
import { collection, addDoc } from 'firebase/firestore'
import _ from 'lodash'
import React from 'react'
import short from 'short-uuid'
import { db } from '../../../firebase'
import { addVendorFormSchema, initialVendorValues } from './constants'
import AutoForm from '../autoform/AutoForm'
import { VendorStatus, VendorTier } from '../../../lib/firestore/types/types'
import { selectVendorsById } from '../../../state/selectors/firestore'
import { Collection } from '../../../lib/firestore/collections'

function NewVendorAccountForm() {
  const navigate = useNavigate()

  const vendorsById = useSelector(selectVendorsById)
  const vendorSKUs = _.map(_.values(vendorsById), 'skuPrefix')

  const onSubmit = async ({
    brandName,
    email,
    uid,
    commissionPercentage,
    dropShippingAllowed,
    contractType,
    contract,
    contractAddendum,
    initialPassword,
    skuPrefix,
  }: any) => {
    const fields = _.pickBy(
      {
        brandName,
        email,
        uid,
        commissionPercentage,
        dropShippingAllowed,
        contractType,
        contract,
        contractAddendum,
        initialPassword,
        skuPrefix,
        tier: VendorTier.stratus,
        status: VendorStatus.onboarding,
      },
      _.identity,
    )
    const ref = await addDoc(collection(db, Collection.vendors), fields)
    const vendorRole = {
      vendorId: ref.id,
      uid: fields.uid,
    }
    await addDoc(collection(db, Collection.vendorRoles), vendorRole)
    navigate(`/vendors/${ref.id}`)
  }

  const [first, ...rest] = addVendorFormSchema

  const formSchema = [
    first,
    {
      id: 'skuPrefix',
      name: 'SKU Prefix',
      type: 'text',
      initialState: '',
      help: (
        <Text weight="lighter" size="xsmall">
          4 character code that will act as universal identifier for brand (SKU prefix, etc)
        </Text>
      ),
      transform: (val: string) => {
        return val.toUpperCase().slice(0, 4)
      },
      // eslint-disable-next-line react/no-unstable-nested-components
      validate: (val: string) => {
        if (val.length !== 4) {
          return 'SKU prefix must be 4 characters long, uppercase characters and numbers'
        }
        if (_.includes(vendorSKUs, val)) {
          const vendor: any = _.find(_.values(vendorsById), { skuPrefix: val })
          return (
            <Text size="xsmall">
              <span>{val} is already already the SKU prefix of an existing </span>
              <Anchor href={`/vendors/${vendor.id}`} target="_blank">
                vendor
              </Anchor>
              <span>, please choose another value</span>
            </Text>
          )
        }
      },
      required: true,
    },
    ...rest,
  ]

  return (
    <Box>
      <AutoForm
        initialValues={{
          ...initialVendorValues,
          initialPassword: short.generate(),
        }}
        formSchema={formSchema}
        readOnlyMode={false}
        onSubmit={onSubmit}
        onCancel={() => navigate(`/vendors`)}
      />
    </Box>
  )
}

export default NewVendorAccountForm
