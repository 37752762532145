import { Box } from 'grommet'
import React from 'react'
import PageTitle from '../../utils/PageTitle'
import EditProductForm from '../../forms/product/EditProductForm'

function EditProduct() {
  return (
    <Box>
      <PageTitle>Edit Product</PageTitle>
      <EditProductForm />
    </Box>
  )
}

export default EditProduct
