import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import {
  VendorRole,
  Vendor,
  VendorProfile,
  DropShipment,
  Admin,
  ShippingProfile,
  PaymentMethod,
  ShopifyFulfillment,
} from '../../lib/firestore/types/types'

import { Product } from '../../lib/firestore/types/productTypes'

type VendorRoles = { [key: string]: VendorRole }
type Vendors = { [key: string]: Vendor }
type VendorProfiles = { [key: string]: VendorProfile }
type DropShipments = { [key: string]: DropShipment }
type Products = { [key: string]: Product }
type Admins = { [key: string]: Admin }
type ShippingProfiles = { [key: string]: ShippingProfile }
type PaymentMethods = { [key: string]: PaymentMethod }
type ShopifyFulfillments = { [key: string]: ShopifyFulfillment }

interface FirestoreState {
  vendorRoles: VendorRoles
  vendors: Vendors
  vendorProfiles: VendorProfiles
  dropShipments: DropShipments
  products: Products
  admins: Admins
  shippingProfiles: ShippingProfiles
  paymentMethods: PaymentMethods
  shopifyFulfillments: ShopifyFulfillments
}

// Define the initial state using that type
const initialState: FirestoreState = {
  vendorRoles: {},
  vendors: {},
  vendorProfiles: {},
  dropShipments: {},
  products: {},
  admins: {},
  shippingProfiles: {},
  paymentMethods: {},
  shopifyFulfillments: {},
}

export const firestoreSlice = createSlice({
  name: 'firestore',
  initialState,
  reducers: {
    addVendorRoles: (state, action: PayloadAction<VendorRoles>) => {
      return {
        ...state,
        vendorRoles: {
          ...state.vendorRoles,
          ...action.payload,
        },
      }
    },
    addVendors: (state, action: PayloadAction<Vendors>) => {
      return {
        ...state,
        vendors: {
          ...state.vendors,
          ...action.payload,
        },
      }
    },
    addVendorProfiles: (state, action: PayloadAction<VendorProfiles>) => {
      return {
        ...state,
        vendorProfiles: {
          ...state.vendorProfiles,
          ...action.payload,
        },
      }
    },
    addDropShipments: (state, action: PayloadAction<DropShipments>) => {
      return {
        ...state,
        dropShipments: {
          ...state.dropShipments,
          ...action.payload,
        },
      }
    },
    addProducts: (state, action: PayloadAction<Products>) => {
      return {
        ...state,
        products: {
          ...state.products,
          ...action.payload,
        },
      }
    },
    addAdmins: (state, action: PayloadAction<Admins>) => {
      return {
        ...state,
        admins: {
          ...state.admins,
          ...action.payload,
        },
      }
    },
    addShippingProfiles: (state, action: PayloadAction<ShippingProfiles>) => {
      return {
        ...state,
        shippingProfiles: {
          ...state.shippingProfiles,
          ...action.payload,
        },
      }
    },
    addPaymentMethods: (state, action: PayloadAction<PaymentMethods>) => {
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          ...action.payload,
        },
      }
    },
    addShopifyFulfillments: (state, action: PayloadAction<ShopifyFulfillments>) => {
      return {
        ...state,
        shopifyFulfillments: {
          ...state.shopifyFulfillments,
          ...action.payload,
        },
      }
    },
  },
})

export const {
  addVendorRoles,
  addVendors,
  addVendorProfiles,
  addDropShipments,
  addProducts,
  addAdmins,
  addShippingProfiles,
  addPaymentMethods,
  addShopifyFulfillments,
} = firestoreSlice.actions

export default firestoreSlice.reducer
