import { Text, CheckBoxGroup } from 'grommet'
import React from 'react'
import _ from 'lodash'
import { ReadOnlyBox, ReadOnlyTitle } from '../ViewComponents'
import AutoFormField from '../AutoFormField'

function CheckboxGroupField({
  id,
  name,
  placeholder,
  transformFn,
  formState,
  required,
  help,
  validate,
  disabled,
  setFormState,
  readOnlyMode,
  width,
  errors,
  setFormErrors,
  validators,
  options,
}: any) {
  const value = _.get(formState, id) || []
  const isObjectOptions = _.isObject(_.first(options))

  if (readOnlyMode) {
    const readOnlyValue = isObjectOptions ? value.map((v) => options.find((option) => option.value === v).label) : value
    return (
      <ReadOnlyBox key={id} width={width || '100%'}>
        <ReadOnlyTitle>{name}</ReadOnlyTitle>
        <Text>{readOnlyValue.join(', ')}</Text>
      </ReadOnlyBox>
    )
  }

  const validateOnBlur = () => {
    const validator = _.get(validators, id)
    const error = validator(value)
    const newFormErrors = _.set(_.cloneDeep(errors), id, error)
    setFormErrors(newFormErrors)
  }

  return (
    <AutoFormField
      id={id}
      key={id}
      name={name}
      required={required}
      help={help}
      validate={validate}
      disabled={disabled}
      width={width}
      error={_.get(errors, id)}
    >
      <CheckBoxGroup
        disabled={disabled}
        name={id}
        value={value}
        placeholder={placeholder}
        onChange={(e: any) => {
          const val = transformFn(e.value)
          const newFormState = _.set(_.cloneDeep(formState), id, val)
          setFormState(newFormState)
        }}
        onBlur={validateOnBlur}
        options={options}
      />
    </AutoFormField>
  )
}

export default CheckboxGroupField
