import React from 'react'
import { Box, Button } from 'grommet'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import AutoForm from '../autoform/AutoForm'
import { selectProductsById, selectSignedInRole, selectVendors } from '../../../state/selectors/firestore'
import { Vendor } from '../../../lib/firestore/types/types'
import { productFormSchema } from './constants'

function ViewProductForm() {
  const params = useParams()
  const productId: any = params.id
  const navigate = useNavigate()
  const role = useSelector(selectSignedInRole)
  const vendors: Vendor[] = useSelector(selectVendors)
  const productsById = useSelector(selectProductsById)
  const product = productsById[productId]
  if (!product) {
    return null
  }

  const vendorBrandNames = vendors.map((vendor: any) => vendor.brandName)

  const formSchema = [
    {
      id: 'vendorName',
      name: 'Vendor',
      type: 'select',
      options: vendorBrandNames,
      required: true,
      search: true,
      disabled: role === 'vendor',
    },
    ...productFormSchema,
  ]

  const shopifyButton = product.shopifyId ? (
    <Button primary label="Update Shopify Product" onClick={() => {}} />
  ) : (
    <Button primary label="Create product on Shopify" onClick={() => {}} />
  )
  return (
    <Box>
      <AutoForm readOnlyMode initialValues={product} formSchema={formSchema} />
      <Button
        margin={{ bottom: 'medium' }}
        secondary
        label="Edit"
        onClick={() => navigate(`/products/${product.id}/edit`)}
      />
      {role === 'admin' && shopifyButton}
    </Box>
  )
}

export default ViewProductForm
