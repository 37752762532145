import _ from 'lodash'
import { Anchor, Box, Text, TextInput } from 'grommet'
import React from 'react'
import styled from 'styled-components'
import { NoneValue, ReadOnlyBox, ReadOnlyTitle } from '../ViewComponents'
import AutoFormField from '../AutoFormField'
import { FieldProps } from './types'

const defaultValues: any = {
  text: '',
  email: '',
  url: '',
  number: '',
}

type TextFieldProps = FieldProps & {
  icon: any
  type: string
}

const TextFieldInput = styled(TextInput)`
  /* Removes arrows/spinners for input type number */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  ::placeholder {
    color: #aaa; /* Specify your desired color */
  }
`

function TextField({
  id,
  name,
  type,
  placeholder,
  transformFn,
  formState,
  required,
  help,
  validate,
  disabled,
  setFormState,
  icon,
  readOnlyMode,
  readOnlyFormattedValue,
  width,
  errors,
  setFormErrors,
  validators,
  setGlobalValueOnChange,
}: TextFieldProps) {
  const value = _.get(formState, id) || defaultValues[type]
  const isSubField = _.includes(id, '.')

  if (readOnlyMode) {
    if (isSubField) {
      return (
        <Box key={id} margin={{ right: 'medium' }}>
          <Text>{_.get(formState, id) || <NoneValue />}</Text>
          <Text size="xsmall">{name}</Text>
        </Box>
      )
    }

    return (
      <ReadOnlyBox key={id} width="100%">
        <ReadOnlyTitle>{name}</ReadOnlyTitle>
        {type === 'url' ? (
          <Anchor
            href={value}
            label={readOnlyFormattedValue}
            target="_blank"
            reverse
            onClick={(e) => e.stopPropagation()}
            weight="normal"
          />
        ) : (
          <Text>{readOnlyFormattedValue}</Text>
        )}
      </ReadOnlyBox>
    )
  }

  const validateOnBlur = () => {
    const validator = _.get(validators, id)
    const error = validator(value)
    const newFormErrors = _.set(_.cloneDeep(errors), id, error)
    setFormErrors(newFormErrors)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = transformFn(event.target.value)
    if (type === 'number') {
      const parsedValue = Number(newValue)
      if (_.isNaN(parsedValue) || parsedValue < 0) {
        newValue = ''
      } else {
        newValue = parsedValue.toString()
      }
    }

    const newFormState = _.set(_.cloneDeep(formState), id, newValue)
    setFormState(newFormState)

    if (setGlobalValueOnChange) {
      setGlobalValueOnChange(newValue, id, formState, setFormState)
    }
  }

  return (
    <AutoFormField
      id={id}
      key={id}
      name={name}
      required={required}
      help={help}
      validate={validate}
      disabled={disabled}
      width={width}
      error={_.get(errors, id)}
    >
      <TextFieldInput
        disabled={disabled}
        type={type}
        name={id}
        value={value}
        placeholder={placeholder}
        icon={icon}
        onChange={handleChange}
        onBlur={validateOnBlur}
      />
    </AutoFormField>
  )
}

export default TextField
