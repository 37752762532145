import React from 'react'
import styled from 'styled-components'
import firebase from 'firebase/compat/app'
import { Box } from 'grommet'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import { isVendorMode } from '../../utils'

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: isVendorMode ? '/products' : '/vendors',
  // We will display Google and Facebook as auth providers.
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID, firebase.auth.EmailAuthProvider.PROVIDER_ID],
}

const FullScreenBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
`

export function SignInScreen() {
  return (
    <FullScreenBox>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    </FullScreenBox>
  )
}
