import styled from 'styled-components'
import { Box } from 'grommet'

export const PDFsBox = styled(Box)<{ readOnly: boolean }>`
  flex-direction: row;
  background-color: ${(props: any) => {
    return props.readOnly ? 'initial' : '#f9f9f9'
  }};
  padding-right: 10px;
`
export const PDFBox = styled(Box)`
  cursor: pointer;
`
