import { Box } from 'grommet'
import React from 'react'
import PageTitle from '../../utils/PageTitle'
import NewProductForm from '../../forms/product/NewProductForm'

function NewProduct() {
  return (
    <Box>
      <PageTitle>New Product</PageTitle>
      <NewProductForm />
    </Box>
  )
}

export default NewProduct
