import { Box } from 'grommet'
import React from 'react'
import NewVendorAccountForm from '../../../forms/vendorAccount/NewVendorAccountForm'
import PageTitle from '../../../utils/PageTitle'

function NewVendorAccount() {
  return (
    <Box>
      <PageTitle>New Vendor</PageTitle>
      <NewVendorAccountForm />
    </Box>
  )
}

export default NewVendorAccount
