import { Box } from 'grommet'
import React from 'react'
import EditShippingProfileForm from '../../forms/shippingProfile/EditShippingProfileForm'
import PageTitle from '../../utils/PageTitle'

function EditShippingProfile() {
  return (
    <Box>
      <PageTitle>Edit Shipping Profile</PageTitle>
      <EditShippingProfileForm />
    </Box>
  )
}

export default EditShippingProfile
