import React from 'react'
import { Box, Button } from 'grommet'
import { useNavigate, useParams } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import AutoForm from '../autoform/AutoForm'
import { selectDropShipmentsById, selectSignedInRole } from '../../../state/selectors/firestore'
// import { Vendor } from '../../../lib/firestore/types/types'
import { dropShippingFulfillmentFormSchema } from './constants'
// import { mapFirestoreFieldsToPaymentMethodFormFields } from '../paymentMethod/utils'

function ViewDropShippingFulfillmentForm() {
  const params = useParams()
  const dropShippingFulfillmentId: any = params.id
  const navigate = useNavigate()
  // const vendors: Vendor[] = useSelector(selectVendors)
  const dropShippingFulfillmentsById = useSelector(selectDropShipmentsById)
  const dropShippingFulfillment = dropShippingFulfillmentsById[dropShippingFulfillmentId]
  const role = useSelector(selectSignedInRole, shallowEqual)

  if (!dropShippingFulfillment) {
    return null
  }

  // const vendorBrandNames = vendors.map((vendor: any) => vendor.brandName)

  const formSchema = [
    ...dropShippingFulfillmentFormSchema,
    {
      id: 'submissionDate',
      name: 'Date Submitted',
      type: 'date',
      required: true,
    },
  ]

  // const initialValues = mapFirestoreFieldsToPaymentMethodFormFields(dropShippingFulfillment)
  return (
    <Box>
      <AutoForm initialValues={dropShippingFulfillment} readOnlyMode formSchema={formSchema} />
      {role === 'admin' && (
        <Button
          secondary
          label="Edit"
          onClick={() => navigate(`/dropShippingFulfillments/${dropShippingFulfillment.id}/edit`)}
        />
      )}
    </Box>
  )
}

export default ViewDropShippingFulfillmentForm
