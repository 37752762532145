import React from 'react'
import { Box, Button } from 'grommet'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import AutoForm from '../autoform/AutoForm'
import { selectPaymentMethodsById, selectSignedInRole, selectVendors } from '../../../state/selectors/firestore'
import { Vendor } from '../../../lib/firestore/types/types'
import { paymentMethodFormSchema } from './constants'
import { mapFirestoreFieldsToPaymentMethodFormFields } from './utils'

function ViewPaymentMethodForm() {
  const params = useParams()
  const paymentMethodId: any = params.id
  const navigate = useNavigate()
  const role = useSelector(selectSignedInRole)
  const vendors: Vendor[] = useSelector(selectVendors)
  const paymentMethodsById = useSelector(selectPaymentMethodsById)
  const paymentMethod = paymentMethodsById[paymentMethodId]
  if (!paymentMethod) {
    return null
  }

  const vendorBrandNames = vendors.map((vendor: any) => vendor.brandName)

  const formSchema = [
    {
      id: 'vendorName',
      name: 'Vendor',
      type: 'select',
      options: vendorBrandNames,
      required: true,
      disabled: role === 'vendor',
    },
    ...paymentMethodFormSchema,
  ]

  return (
    <Box>
      <AutoForm
        readOnlyMode
        initialValues={mapFirestoreFieldsToPaymentMethodFormFields(paymentMethod)}
        formSchema={formSchema}
      />
      <Button secondary label="Edit" onClick={() => navigate(`/paymentMethods/${paymentMethod.id}/edit`)} />
    </Box>
  )
}

export default ViewPaymentMethodForm
