import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Box, Button } from 'grommet'
import React from 'react'
import { addShippingRateFormSchema } from './constants'
import AutoForm from '../autoform/AutoForm'
import { selectShippingProfilesById, selectVendors } from '../../../state/selectors/firestore'

function ViewShippingProfileForm() {
  const params = useParams()
  const navigate = useNavigate()
  const shippingProfileId: any = params.id
  const vendors = useSelector(selectVendors)
  const shippingProfilesById = useSelector(selectShippingProfilesById)
  const shippingProfile = shippingProfilesById[shippingProfileId]
  const vendorBrandNames = vendors.map((vendor) => vendor.brandName)

  if (!shippingProfile) {
    return null
  }
  const dynamicSchema = [
    {
      id: 'vendorName',
      name: 'Vendor',
      type: 'select',
      options: vendorBrandNames,
      search: true,
      required: true,
      // hidden: role === 'vendor',
    },
    ...addShippingRateFormSchema,
  ]
  const vendor = vendors.find(({ skuPrefix }) => skuPrefix === shippingProfile.vendorSkuPrefix)
  const vendorName = vendor?.brandName
  const initialValues = {
    ...shippingProfile,
    vendorName,
  }
  return (
    <Box>
      <AutoForm initialValues={initialValues} formSchema={dynamicSchema} readOnlyMode />
      <Button secondary label="Edit" onClick={() => navigate(`/shippingProfiles/${shippingProfileId}/edit`)} />
    </Box>
  )
}

export default ViewShippingProfileForm
