import { Box } from 'grommet'
import React from 'react'
import ViewDropShippingFulfillmentForm from '../../forms/dropShippingFulfillment/ViewDropShippingFulfillmentForm'
import PageTitle from '../../utils/PageTitle'

function DropShippingFulfillment() {
  return (
    <Box>
      <PageTitle>Drop Shipping Fulfillment</PageTitle>
      <ViewDropShippingFulfillmentForm />
    </Box>
  )
}

export default DropShippingFulfillment
