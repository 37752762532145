import React from 'react'
import { Box } from 'grommet'
import { ReadOnlyBox, ReadOnlyTitle, SubFieldsContainer } from '../ViewComponents'
import AutoFormField from '../AutoFormField'
import { renderField } from '../fieldRenderer'
import { createListFields } from '../utils'

function ListField({
  id,
  name,
  formState,
  setFormState,
  readOnlyMode,
  required,
  help,
  validate,
  disabled,
  width,
  errors,
  setFormErrors,
  field,
  count,
  validators,
}: any) {
  const listFields = createListFields(field, count, formState)

  if (readOnlyMode) {
    return (
      <ReadOnlyBox key={id} width="100%">
        <ReadOnlyTitle>{name}</ReadOnlyTitle>
        <Box direction="row-responsive">
          {listFields.map((listField) => {
            const subfield = {
              ...listField,
              id: `${id}.${listField.id}`,
            }
            return renderField(subfield, formState, setFormState, readOnlyMode, errors, setFormErrors, validators)
          })}
        </Box>
      </ReadOnlyBox>
    )
  }

  return (
    <AutoFormField
      id={id}
      key={id}
      name={name}
      required={required}
      help={help}
      validate={validate}
      disabled={disabled}
      width={width}
    >
      <SubFieldsContainer direction="column">
        {listFields.map((listField: any) => {
          const subfield = {
            ...listField,
            id: `${id}.${listField.id}`,
          }
          return renderField(subfield, formState, setFormState, readOnlyMode, errors, setFormErrors, validators)
        })}
      </SubFieldsContainer>
    </AutoFormField>
  )
}

export default ListField
