import { Text, Box, DataTable, Button } from 'grommet'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PageTitle from '../../utils/PageTitle'
import { selectProducts } from '../../../state/selectors/firestore'

function Products() {
  const products = useSelector(selectProducts)
  const navigate = useNavigate()
  const ProductTable = (
    <DataTable
      sortable
      onClickRow={(e: any) => {
        navigate(`/products/${e?.datum?.id}`)
      }}
      columns={[
        {
          property: 'title',
          header: <Text>Title</Text>,
          primary: true,
          search: true,
          sortable: true,
        },
        {
          property: 'vendorName',
          header: 'Vendor',
        },
      ]}
      data={products}
    />
  )

  const actionButton = <Button secondary label="Add Product" onClick={() => navigate('/products/new')} />

  return (
    <Box>
      <PageTitle actionButton={actionButton}>Products</PageTitle>
      {ProductTable}
    </Box>
  )
}

export default Products
