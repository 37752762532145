import _ from 'lodash'
import { DateInput, Text } from 'grommet'
import React from 'react'
import styled from 'styled-components'
import { ReadOnlyBox, ReadOnlyTitle } from '../ViewComponents'
import AutoFormField from '../AutoFormField'
import { FieldProps } from './types'

const ReadOnlyTextArea = styled(Text)`
  white-space: pre-wrap;
  max-width: 100%;
`

function DateField({
  id,
  name,
  placeholder,
  formState,
  required,
  help,
  validate,
  disabled,
  setFormState,
  readOnlyMode,
  readOnlyFormattedValue,
  width,
  errors,
  setFormErrors,
  validators,
}: FieldProps) {
  const value = _.get(formState, id) || ''

  if (readOnlyMode) {
    return (
      <ReadOnlyBox key={id} width={width || '100%'}>
        <ReadOnlyTitle>{name}</ReadOnlyTitle>
        <ReadOnlyTextArea>{readOnlyFormattedValue}</ReadOnlyTextArea>
      </ReadOnlyBox>
    )
  }

  const validateOnBlur = () => {
    const validator = _.get(validators, id)
    const error = validator(value)
    const newFormErrors = _.set(_.cloneDeep(errors), id, error)
    setFormErrors(newFormErrors)
  }

  return (
    <AutoFormField
      id={id}
      key={id}
      name={name}
      required={required}
      help={help}
      validate={validate}
      disabled={disabled}
      width={width}
      error={_.get(errors, id)}
    >
      <DateInput
        disabled={disabled}
        format="mm/dd/yyyy"
        name={id}
        value={value}
        placeholder={placeholder}
        onChange={(e: any) => {
          const newFormState = _.set(_.cloneDeep(formState), id, e.value)
          setFormState(newFormState)
        }}
        onBlur={validateOnBlur}
      />
    </AutoFormField>
  )
}

export default DateField
