import { Box, DataTable, Button } from 'grommet'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectPaymentMethods, selectSignedInRole } from '../../../state/selectors/firestore'
import PageTitle from '../../utils/PageTitle'
import { PaymentMethod } from '../../../lib/firestore/types/types'

function PaymentMethods() {
  const role = useSelector(selectSignedInRole)
  const paymentMethods: PaymentMethod[] = useSelector(selectPaymentMethods)

  const navigate = useNavigate()
  const PaymentMethodsTable = (
    <DataTable
      sortable
      onClickRow={(e: any) => {
        navigate(`/paymentMethods/${e?.datum?.id}`)
      }}
      columns={[
        ...(role === 'admin'
          ? [
              {
                property: 'vendorName',
                header: 'Vendor',
                primary: true,
                search: true,
                sortable: true,
              },
            ]
          : []),
        {
          property: 'recipientName',
          header: 'Recipient Name',
          search: true,
          sortable: true,
        },
      ]}
      data={paymentMethods}
    />
  )

  const actionButton = <Button secondary label="Add Payment Method" onClick={() => navigate('/paymentMethods/new')} />

  return (
    <Box>
      <PageTitle actionButton={actionButton}>Payment Methods</PageTitle>
      {PaymentMethodsTable}
    </Box>
  )
}

export default PaymentMethods
