import { Box } from 'grommet'
import _ from 'lodash'
import React from 'react'
import { evaluateRequiredField } from './utils'
// import { NoneValue, ReadOnlyBox, ReadOnlyTitle } from './ViewComponents'
import TextField from './fields/TextField'
import TextAreaField from './fields/TextAreaField'
import DateField from './fields/DateField'
import CurrencyField from './fields/CurrencyField'
import CheckboxField from './fields/CheckboxField'
import CheckboxGroupField from './fields/CheckboxGroupField'
import SelectField from './fields/SelectField'
import ImagesField from './fields/ImagesField'
import PDFField from './fields/PDFsField/PDFsField'
import CompoundField from './fields/CompoundField'
import ListField from './fields/ListField'
import AddressField from './fields/AddressField'
import CounterField from './fields/CounterField'

export function renderField(field, formState, setFormState, readOnlyMode, errors, setFormErrors, validators) {
  const { id, name, help, validate, disabled, placeholder, formContent, hidden, condition, width, fields, count }: any =
    field
  const required = evaluateRequiredField(field.required, formState)

  if (condition && !condition(formState)) {
    return null
  }

  if (formContent && readOnlyMode) {
    return null
  }

  if (formContent && !readOnlyMode) {
    return (
      <Box key={JSON.stringify(formContent)} pad={{ horizontal: 'small' }} width={width || '100%'}>
        {formContent}
      </Box>
    )
  }

  if (hidden) {
    return null
  }

  const transformFn = field.transform || _.identity

  // if (readOnlyMode && _.isUndefined(_.get(formState, field.id))) {
  //   return (
  //     <ReadOnlyBox key={field.id}>
  //       <ReadOnlyTitle>{field.name}</ReadOnlyTitle>
  //       <NoneValue />
  //     </ReadOnlyBox>
  //   )
  // }

  const readOnlyValueFn = field.readOnlyValueFormatter || _.identity
  const readOnlyFormattedValue = readOnlyValueFn(_.get(formState, field.id))

  return (() => {
    switch (field.type) {
      case 'text':
      case 'email':
      case 'url':
      case 'number':
        return (
          <TextField
            {...field}
            required={required}
            key={field.id}
            transformFn={transformFn}
            formState={formState}
            setFormState={setFormState}
            readOnlyMode={readOnlyMode}
            readOnlyFormattedValue={readOnlyFormattedValue}
            errors={errors}
            setFormErrors={setFormErrors}
            validators={validators}
          />
        )
        break
      case 'counter':
        return (
          <CounterField
            {...field}
            required={required}
            key={field.id}
            transformFn={transformFn}
            formState={formState}
            setFormState={setFormState}
            readOnlyMode={readOnlyMode}
            readOnlyFormattedValue={readOnlyFormattedValue}
            errors={errors}
            setFormErrors={setFormErrors}
            validators={validators}
          />
        )
        break
      case 'textarea':
        return (
          <TextAreaField
            {...field}
            required={required}
            key={field.id}
            transformFn={transformFn}
            formState={formState}
            setFormState={setFormState}
            readOnlyMode={readOnlyMode}
            readOnlyFormattedValue={readOnlyFormattedValue}
            errors={errors}
            setFormErrors={setFormErrors}
            validators={validators}
          />
        )
        break
      case 'date':
        return (
          <DateField
            key={id}
            readOnlyMode={readOnlyMode}
            readOnlyFormattedValue={readOnlyFormattedValue}
            id={id}
            name={name}
            formState={formState}
            required={required}
            help={help}
            validate={validate}
            placeholder={placeholder}
            disabled={disabled}
            setFormState={setFormState}
            width={width}
            errors={errors}
            setFormErrors={setFormErrors}
            validators={validators}
            transformFn={transformFn}
          />
        )
        break
      case 'currency':
        return (
          <CurrencyField
            key={id}
            readOnlyMode={readOnlyMode}
            readOnlyFormattedValue={readOnlyFormattedValue}
            id={id}
            name={name}
            formState={formState}
            required={required}
            help={help}
            validate={validate}
            placeholder={placeholder}
            disabled={disabled}
            setFormState={setFormState}
            width={width}
            errors={errors}
            setFormErrors={setFormErrors}
            validators={validators}
            transformFn={transformFn}
          />
        )
        break
      case 'checkbox':
        return (
          <CheckboxField
            {...field}
            required={required}
            key={id}
            transformFn={transformFn}
            formState={formState}
            setFormState={setFormState}
            readOnlyMode={readOnlyMode}
            readOnlyFormattedValue={readOnlyFormattedValue}
            errors={errors}
            setFormErrors={setFormErrors}
            validators={validators}
          />
        )
        break
      case 'checkboxgroup':
        return (
          <CheckboxGroupField
            {...field}
            required={required}
            key={id}
            transformFn={transformFn}
            formState={formState}
            setFormState={setFormState}
            readOnlyMode={readOnlyMode}
            readOnlyFormattedValue={readOnlyFormattedValue}
            errors={errors}
            setFormErrors={setFormErrors}
            validators={validators}
          />
        )
        break
      case 'select':
        return (
          <SelectField
            {...field}
            required={required}
            key={id}
            transformFn={transformFn}
            formState={formState}
            setFormState={setFormState}
            readOnlyMode={readOnlyMode}
            readOnlyFormattedValue={readOnlyFormattedValue}
            errors={errors}
            setFormErrors={setFormErrors}
            validators={validators}
          />
        )

        break
      case 'images':
        return (
          <ImagesField
            key={id}
            readOnlyMode={readOnlyMode}
            id={id}
            name={name}
            formState={formState}
            required={required}
            help={help}
            validate={validate}
            disabled={disabled}
            setFormState={setFormState}
            width={width}
            errors={errors}
          />
        )
        break
      case 'pdfs':
        return (
          <PDFField
            key={id}
            readOnlyMode={readOnlyMode}
            id={id}
            name={name}
            formState={formState}
            required={required}
            help={help}
            validate={validate}
            disabled={disabled}
            setFormState={setFormState}
            width={width}
            errors={errors}
          />
        )
        break
      case 'compound':
        return (
          <CompoundField
            {...field}
            required={required}
            key={id}
            formState={formState}
            setFormState={setFormState}
            readOnlyMode={readOnlyMode}
            transformFn={transformFn}
            errors={errors}
            setFormErrors={setFormErrors}
            validators={validators}
            fields={fields}
          />
        )
        break
      case 'list':
        return (
          <ListField
            {...field}
            required={required}
            key={id}
            formState={formState}
            setFormState={setFormState}
            readOnlyMode={readOnlyMode}
            transformFn={transformFn}
            errors={errors}
            setFormErrors={setFormErrors}
            validators={validators}
            count={count}
          />
        )
        break
      case 'address':
        return (
          <AddressField
            {...field}
            key={id}
            required={required}
            formState={formState}
            setFormState={setFormState}
            readOnlyMode={readOnlyMode}
            transformFn={transformFn}
            errors={errors}
            setFormErrors={setFormErrors}
            validators={validators}
          />
        )
        break
    }
  })()
}
