import { Box } from 'grommet'
import React from 'react'
import ViewOnboardingChecklistForm from '../../forms/onboardingChecklist/ViewOnboardingChecklistForm'
import PageTitle from '../../utils/PageTitle'

function OnboardingChecklist() {
  return (
    <Box>
      <PageTitle>Onboarding Checklist</PageTitle>
      <ViewOnboardingChecklistForm />
    </Box>
  )
}

export default OnboardingChecklist
