import { Box } from 'grommet'
import React from 'react'
import EditPaymentMethodForm from '../../forms/paymentMethod/EditPaymentMethodForm'
import PageTitle from '../../utils/PageTitle'

function EditPaymentMethod() {
  return (
    <Box>
      <PageTitle>Edit Payment Method</PageTitle>
      <EditPaymentMethodForm />
    </Box>
  )
}

export default EditPaymentMethod
