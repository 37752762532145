import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Box, Text } from 'grommet'
import React from 'react'
import _ from 'lodash'
import { doc, setDoc } from 'firebase/firestore'
import { editVendorProfileFormSchema } from './constants'
import AutoForm from '../autoform/AutoForm'
import { db } from '../../../firebase'
import { selectVendorsById } from '../../../state/selectors/firestore'
import { Collection } from '../../../lib/firestore/collections'

function EditVendorAccountForm() {
  const params = useParams()
  const navigate = useNavigate()

  const vendorId = params.id
  if (!vendorId) {
    return null
  }

  const vendorsById = useSelector(selectVendorsById)
  const vendor = vendorsById[vendorId]

  if (!vendor) {
    return <Text>Vendor ID Does not exist</Text>
  }

  const onSubmit = async ({
    website,
    socialMedia,
    basedIn,
    mailingAddress,
    brandProfileImage,
    brandDescription,
    pronouns,
    pointOfContact,
    contactLocation,
    contactEmail,
    contactPhoneNumber,
  }: any) => {
    const fields = _.pickBy(
      {
        website,
        socialMedia: _.pickBy(socialMedia, _.identity),
        basedIn: _.pickBy(basedIn, _.identity),
        mailingAddress: _.pickBy(mailingAddress, _.identity),
        brandProfileImage,
        brandDescription,
        pronouns,
        pointOfContact: _.pickBy(pointOfContact, _.identity),
        contactLocation: _.pickBy(contactLocation, _.identity),
        contactEmail,
        contactPhoneNumber,
      },
      _.identity,
    )

    const mergedFields = {
      ...vendor,
      ...fields,
    }

    await setDoc(doc(db, Collection.vendors, vendor.id), mergedFields)
    navigate(`/vendors/${vendor.id}`)
  }

  return (
    <Box>
      <AutoForm
        initialValues={vendor}
        formSchema={editVendorProfileFormSchema}
        onSubmit={onSubmit}
        onCancel={() => navigate(`/vendors/${vendorId}`)}
        readOnlyMode={false}
      />
    </Box>
  )
}

export default EditVendorAccountForm
