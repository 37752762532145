import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Box } from 'grommet'
import { setDoc, doc } from 'firebase/firestore'
import _ from 'lodash'
import React from 'react'
import { db } from '../../../firebase'
import { addShippingRateFormSchema } from './constants'
import AutoForm from '../autoform/AutoForm'
import { selectShippingProfilesById, selectSignedInRole, selectVendors } from '../../../state/selectors/firestore'
import { Collection } from '../../../lib/firestore/collections'
import joi from '../../../lib/firestore/joi'
import { cleanObject } from '../autoform/utils'

function EditShippingProfileForm() {
  const navigate = useNavigate()
  const params = useParams()
  const shippingProfileId: any = params.id
  const vendors = useSelector(selectVendors)
  const role = useSelector(selectSignedInRole)
  const shippingProfilesById = useSelector(selectShippingProfilesById)
  const shippingProfile = shippingProfilesById[shippingProfileId]
  if (!shippingProfile) {
    return null
  }

  const vendorBrandNames = vendors.map((vendor) => vendor.brandName)

  const onSubmit = async ({
    vendorName,
    australia,
    canada,
    europeFlatRate,
    france,
    germany,
    intnlFlatRate,
    italy,
    netherlands,
    newZealand,
    southKorea,
    uk,
    usa,
    isAllEurope,
  }: any) => {
    const vendor = _.find(vendors, { brandName: vendorName })
    const vendorSkuPrefix = vendor?.skuPrefix
    const vendorId = vendor?.id

    const fields = cleanObject({
      vendorName,
      vendorSkuPrefix,
      vendorId,
      australia,
      canada,
      europeFlatRate,
      france,
      germany,
      intnlFlatRate,
      italy,
      netherlands,
      newZealand,
      southKorea,
      uk,
      usa,
      isAllEurope,
    })
    const result = joi.shippingProfile.validate(fields)
    if (result.error) {
      throw new Error(result.error.message)
    }
    await setDoc(doc(db, Collection.shippingProfiles, shippingProfile.id), fields)
    navigate(`/shippingProfiles/${shippingProfile.id}`)
  }

  const dynamicSchema = [
    {
      id: 'vendorName',
      name: 'Vendor',
      type: role === 'vendor' ? 'text' : 'select',
      options: vendorBrandNames,
      search: true,
      required: true,
      disabled: role === 'vendor',
    },
    ...addShippingRateFormSchema,
  ]

  return (
    <Box>
      <AutoForm
        initialValues={shippingProfile}
        formSchema={dynamicSchema}
        readOnlyMode={false}
        onSubmit={onSubmit}
        onCancel={() => navigate(`/shippingProfiles/${shippingProfileId}`)}
      />
    </Box>
  )
}

export default EditShippingProfileForm
