import _ from 'lodash'
import { Box, Select, Text, TextInput } from 'grommet'
import React from 'react'
import styled from 'styled-components'
import { FieldError, NoneValue, ReadOnlyBox, ReadOnlyTitle, SubFieldsContainer } from '../ViewComponents'
import AutoFormField, { RequiredAstrix } from '../AutoFormField'

// eslint-disable-next-line react/prefer-stateless-function
class InputField extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    const {
      field: { options },
    } = this.props
    // Don't call this.setState() here!
    this.state = { filteredOptions: options }
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { field, id, formState, setFormState, value, validateOnBlur, index } = this.props
    const { filteredOptions } = this.state
    switch (field.type) {
      case 'select':
        return (
          <Select
            placeholder={field.placeholder}
            disabled={field.disabled}
            name={id}
            value={_.get(formState, `${id}.${field.id}`) || ''}
            options={filteredOptions}
            onChange={(e) => {
              setFormState({
                ...formState,
                [id]: {
                  ...value,
                  [field.id]: e.target.value,
                },
              })
            }}
            onBlur={() => validateOnBlur(index)}
            onSearch={(text) => {
              // The line below escapes regular expression special characters:
              // [ \ ^ $ . | ? * + ( )
              const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')

              // Create the regular expression with modified value which
              // handles escaping special characters. Without escaping special
              // characters, errors will appear in the console
              const exp = new RegExp(escapedText, 'i')
              this.setState({ filteredOptions: field.options.filter((o: any) => exp.test(o)) })
            }}
          />
        )
      default:
        return (
          <TextInput
            placeholder={field.placeholder}
            disabled={field.disabled}
            type="text"
            name={id}
            value={_.get(formState, `${id}.${field.id}`) || ''}
            onChange={(e) => {
              setFormState({
                ...formState,
                [id]: {
                  ...value,
                  [field.id]: e.target.value,
                },
              })
            }}
            onBlur={() => validateOnBlur(index)}
          />
        )
    }
  }
}

export const ReadOnlyBoxColumn = styled(ReadOnlyBox)`
  flex-direction: column;
`

function AddressField({
  id,
  name,
  formState,
  setFormState,
  readOnlyMode,
  required,
  help,
  validate,
  disabled,
  width,
  errors,
  setFormErrors,
  validators,
  fields,
}: any) {
  const value = formState[id] || {}

  if (readOnlyMode) {
    return (
      <ReadOnlyBox key={id} width="100%">
        <ReadOnlyTitle>{name}</ReadOnlyTitle>
        {_.isEmpty(_.compact(_.values(value))) ? (
          <NoneValue />
        ) : (
          <Box direction="column">
            <Text>{value.addressLine1}</Text>
            <Text>{value.addressLine2}</Text>
            <Box direction="row">
              <Text margin={{ right: 'xsmall' }}>{value.city}</Text>
              <Text margin={{ right: 'xsmall' }}>{value.state}</Text>
              <Text margin={{ right: 'xsmall' }}>{value.zip}</Text>
            </Box>
            <Box direction="row">
              <Text>{value.country}</Text>
            </Box>
          </Box>
        )}
      </ReadOnlyBox>
    )
  }

  const validateOnBlur = (index: number) => {
    const field = fields[index]
    setFormErrors({
      ...errors,
      [id]: {
        ...(errors[id] || {}),
        [field.id]: validators[id][field.id](formState[id][field.id]),
      },
    })
  }

  const fieldWidths = ['100%', '100%', '48%', '48%', '48%', '48%']

  return (
    <AutoFormField
      id={id}
      key={id}
      name={name}
      required={required}
      help={help}
      validate={validate}
      disabled={disabled}
      width={width}
    >
      <SubFieldsContainer direction="row" justify="between" wrap>
        {fields.map((field: any, index: number) => {
          return (
            <Box key={field.id} width={fieldWidths[index]} margin={{ bottom: 'xsmall' }}>
              <InputField
                field={field}
                id={id}
                formState={formState}
                setFormState={setFormState}
                value={value}
                validateOnBlur={validateOnBlur}
                index={index}
              />
              <FieldError>{_.get(errors, `${id}.${field.id}`)}</FieldError>
              <Text size="xsmall">
                {field.name || field.id} <RequiredAstrix>{field.required ? '*' : ''}</RequiredAstrix>
              </Text>
            </Box>
          )
        })}
      </SubFieldsContainer>
    </AutoFormField>
  )
}

export default AddressField
