import { Text, Box } from 'grommet'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import EditVendorAccountForm from '../../../forms/vendorAccount/EditVendorAccountForm'
import { selectVendorsById } from '../../../../state/selectors/firestore'
import PageTitle from '../../../utils/PageTitle'

function EditVendorAccount() {
  const params = useParams()

  const vendorId = params.id
  if (!vendorId) {
    return null
  }

  const vendorsById = useSelector(selectVendorsById)
  const vendor = vendorsById[vendorId]

  if (!vendor) {
    return <Text>Vendor ID Does not exist</Text>
  }

  return (
    <Box>
      <PageTitle>Edit Vendor Account</PageTitle>
      <EditVendorAccountForm />
    </Box>
  )
}

export default EditVendorAccount
