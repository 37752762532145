import React from 'react'
import { Box } from 'grommet'
import { addDoc, collection } from 'firebase/firestore'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { db } from '../../../firebase'
import AutoForm from '../autoform/AutoForm'
import { selectProductsById, selectVendorsById } from '../../../state/selectors/firestore'
import { productFormSchema } from './constants'
import joi from '../../../lib/firestore/joi'
import { Collection } from '../../../lib/firestore/collections'

function EditProductForm() {
  const params = useParams()
  const productId: any = params.id
  const productsById = useSelector(selectProductsById)
  const product = productsById[productId]
  const navigate = useNavigate()
  // const role = useSelector(selectSignedInRole)
  const vendorsById = useSelector(selectVendorsById)

  if (!product) {
    return null
  }

  const formSchema = [
    {
      id: 'vendorName',
      name: 'Vendor',
      type: 'text',
      required: true,
      disabled: true,
    },
    ...productFormSchema,
  ]

  const onSubmit = async ({
    title,
    images,
    gender,
    type,
    topsSubtype,
    dressesSubtype,
    onePiecesSubtype,
    pantsSubtype,
    skirtsSubtype,
    intimatesSwimSubtype,
    activewearSubtype,
    outerwearSubtype,
    bagsSubtype,
    accessoriesSubtype,
    shoesSubtype,
    jewlerySubtype,
    suitSetSubtype,
    copy,
    sizeOptionCount,
    colorOptionCount,
    price,
    sizeOptions,
    colorOptions,
  }: any) => {
    const fields = _.pickBy(
      {
        title,
        images,
        vendorId: product.vendorId,
        gender,
        type,
        topsSubtype,
        dressesSubtype,
        onePiecesSubtype,
        pantsSubtype,
        skirtsSubtype,
        intimatesSwimSubtype,
        activewearSubtype,
        outerwearSubtype,
        bagsSubtype,
        accessoriesSubtype,
        shoesSubtype,
        jewlerySubtype,
        suitSetSubtype,
        copy,
        sizeOptionCount,
        colorOptionCount,
        price,
        sizeOptions,
        colorOptions,
      },
      _.identity,
    )
    const result = joi.productSchema.validate(fields)
    if (result.error) {
      throw new Error(result.error.message)
    } else {
      console.log(result)
    }
    const ref = await addDoc(collection(db, Collection.products), fields)
    navigate(`/products/${ref.id}`)
  }

  console.log({
    formSchema,
    vendorName: vendorsById[product.vendorId].brandName,
  })

  return (
    <Box>
      <AutoForm
        readOnlyMode={false}
        initialValues={product}
        formSchema={formSchema}
        onSubmit={onSubmit}
        onCancel={() => navigate('/products')}
      />
    </Box>
  )
}

export default EditProductForm
