import React from 'react'
import { Box } from 'grommet'
import { addDoc, collection } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { db } from '../../../firebase'
import AutoForm from '../autoform/AutoForm'
import { selectSignedInRole, selectSignedInVendor, selectVendors } from '../../../state/selectors/firestore'
import { Vendor } from '../../../lib/firestore/types/types'
import { newProductFormDefaults, productFormSchema } from './constants'
import joi from '../../../lib/firestore/joi'
import { Collection } from '../../../lib/firestore/collections'

function NewProductForm() {
  const navigate = useNavigate()
  const role = useSelector(selectSignedInRole)
  const vendors: Vendor[] = useSelector(selectVendors)
  const signedInVendor = useSelector(selectSignedInVendor)

  const vendorBrandNames = vendors.map((vendor: any) => vendor.brandName)

  const formSchema = [
    {
      id: 'vendorName',
      name: 'Vendor',
      type: 'select',
      options: vendorBrandNames,
      required: true,
      search: true,
      disabled: role === 'vendor',
    },
    ...productFormSchema,
  ]

  const onSubmit = async ({
    vendorName,
    title,
    images,
    gender,
    type,
    topsSubtype,
    dressesSubtype,
    onePiecesSubtype,
    pantsSubtype,
    skirtsSubtype,
    intimatesSwimSubtype,
    activewearSubtype,
    outerwearSubtype,
    bagsSubtype,
    accessoriesSubtype,
    shoesSubtype,
    jewlerySubtype,
    suitSetSubtype,
    copy,
    sizeOptionCount,
    colorOptionCount,
    price,
    sizeOptions,
    colorOptions,
    variants,
  }: any) => {
    const vendorId = vendors.find((vendor) => vendor.brandName === vendorName)?.id
    const fields = _.pickBy(
      {
        title,
        images,
        vendorId,
        gender,
        type,
        topsSubtype,
        dressesSubtype,
        onePiecesSubtype,
        pantsSubtype,
        skirtsSubtype,
        intimatesSwimSubtype,
        activewearSubtype,
        outerwearSubtype,
        bagsSubtype,
        accessoriesSubtype,
        shoesSubtype,
        jewlerySubtype,
        suitSetSubtype,
        copy,
        sizeOptionCount,
        colorOptionCount,
        price,
        sizeOptions: sizeOptions.filter((option: any, index) => index < sizeOptionCount),
        colorOptions: colorOptions.filter((option: any, index) => index < colorOptionCount),
        variants,
      },
      _.identity,
    )
    debugger
    const result = joi.productSchema.validate(fields)
    if (result.error) {
      throw new Error(result.error.message)
    } else {
      console.log(result)
    }
    const ref = await addDoc(collection(db, Collection.products), fields)
    navigate(`/products/${ref.id}`)
  }

  return (
    <Box>
      <AutoForm
        readOnlyMode={false}
        initialValues={{
          ...newProductFormDefaults,
          vendorName: signedInVendor?.brandName,
        }}
        formSchema={formSchema}
        onSubmit={onSubmit}
        onCancel={() => navigate('/products')}
      />
    </Box>
  )
}

export default NewProductForm
