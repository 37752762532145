import _ from 'lodash'
import {
  addAdmins,
  addDropShipments,
  addProducts,
  addVendors,
  addShippingProfiles,
  addPaymentMethods,
  addShopifyFulfillments,
  addVendorRoles,
} from '../reducers/firestore'
import { subscribeToCollection, subscribeToCollectionWithQuery, subscribeToDocument } from '../../lib/firestore/utils'
import { selectSignedInRole, selectSignedInVendor, selectVendorRole } from '../selectors/firestore'
import { Collection } from '../../lib/firestore/collections'

function initializeAdminFirestoreData(storeAPI) {
  subscribeToCollection(Collection.vendors, (docs: any) => {
    storeAPI.dispatch(addVendors(_.keyBy(docs, 'id')))
  })
  subscribeToCollection(Collection.dropShipments, (documents: any) => {
    storeAPI.dispatch(addDropShipments(_.keyBy(documents, 'id')))
  })
  subscribeToCollection(Collection.products, (documents: any) => {
    storeAPI.dispatch(addProducts(_.keyBy(documents, 'id')))
  })
  subscribeToCollection(Collection.shippingProfiles, (documents: any) => {
    storeAPI.dispatch(addShippingProfiles(_.keyBy(documents, 'id')))
  })
  subscribeToCollection(Collection.paymentMethods, (documents: any) => {
    storeAPI.dispatch(addPaymentMethods(_.keyBy(documents, 'id')))
  })
  subscribeToCollectionWithQuery(
    Collection.shopifyFulfillments,
    [
      ['trackingNumber', '==', null],
      ['status', '!=', 'cancelled'],
    ],
    (documents: any) => {
      storeAPI.dispatch(addShopifyFulfillments(_.keyBy(documents, 'id')))
    },
  )
}

function initializeVendorFirestoreData(storeAPI) {
  const state = storeAPI.getState()
  const signedInVendor: any = selectSignedInVendor(state)
  subscribeToCollectionWithQuery(Collection.dropShipments, ['vendorId', '==', signedInVendor.id], (documents: any) => {
    storeAPI.dispatch(addDropShipments(_.keyBy(documents, 'id')))
  })
  subscribeToCollectionWithQuery(Collection.products, ['vendorId', '==', signedInVendor.id], (documents: any) => {
    storeAPI.dispatch(addProducts(_.keyBy(documents, 'id')))
  })
  subscribeToCollectionWithQuery(
    Collection.shippingProfiles,
    ['vendorId', '==', signedInVendor.id],
    (documents: any) => {
      storeAPI.dispatch(addShippingProfiles(_.keyBy(documents, 'id')))
    },
  )
  subscribeToCollectionWithQuery(Collection.paymentMethods, ['vendorId', '==', signedInVendor.id], (documents: any) => {
    storeAPI.dispatch(addPaymentMethods(_.keyBy(documents, 'id')))
  })
  subscribeToCollectionWithQuery(
    Collection.shopifyFulfillments,
    [
      ['trackingNumber', '==', null],
      ['status', '!=', 'cancelled'],
      ['vendorId', '==', signedInVendor.id],
    ],
    (documents: any) => {
      storeAPI.dispatch(addShopifyFulfillments(_.keyBy(documents, 'id')))
    },
  )
}

const firestoreMiddleware = (storeAPI: any) => (next: any) => (action: any) => {
  if (action.type === 'firebaseAuth/setUID') {
    subscribeToCollection('vendorRoles', (vendorRolesDocs: any) => {
      storeAPI.dispatch(addVendorRoles(_.keyBy(vendorRolesDocs, 'id')))
      subscribeToCollection('admins', (adminDocuments: any) => {
        storeAPI.dispatch(addAdmins(adminDocuments))
        const state = storeAPI.getState()
        const role = selectSignedInRole(state)
        const vendorRole: any = selectVendorRole(state)
        if (role === 'admin') {
          initializeAdminFirestoreData(storeAPI)
        } else if (role === 'vendor') {
          subscribeToDocument(Collection.vendors, vendorRole.vendorId, (document: any) => {
            storeAPI.dispatch(addVendors(_.keyBy([document], 'id')))
            initializeVendorFirestoreData(storeAPI)
          })
        }
      })
    })
  }
  const result = next(action)
  return result
}

export default firestoreMiddleware
