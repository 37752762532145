import { Box } from 'grommet'
import React from 'react'
import NewShippingProfileForm from '../../forms/shippingProfile/NewShippingProfileForm'
import PageTitle from '../../utils/PageTitle'

function NewShippingProfile() {
  return (
    <Box>
      <PageTitle>New Shipping Profile</PageTitle>
      <NewShippingProfileForm />
    </Box>
  )
}

export default NewShippingProfile
