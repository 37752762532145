import React from 'react'
import { ResponsiveContext } from 'grommet'

export function isViewCollapsed() {
  const size = React.useContext(ResponsiveContext)
  const [collapsed, setIsCollapsed] = React.useState(size === 'xsmall' || size === 'small' || size === 'medium')

  React.useEffect(() => {
    setIsCollapsed(size === 'xsmall' || size === 'small' || size === 'medium')
  }, [size])
  return collapsed
}
