import React from 'react'
import { createPortal } from 'react-dom'
import _ from 'lodash'
import { Anchor, Box, Text } from 'grommet'
import { ref, uploadBytes } from 'firebase/storage'
import { FormClose } from 'grommet-icons'
import styled from 'styled-components'
import { storage } from '../../../../../firebase'
import { PDFsBox, PDFBox } from './ViewComponents'
import ReadOnlyPDFsField from './ReadOnlyPDFsField'
import AutoFormField from '../../AutoFormField'

const DeletePDFIconBox = styled(Box)`
  cursor: pointer;
`

const HiddenFileInput = styled.input`
  display: none !important;
  //opacity: 0;
  //background-color: #039be5;
  //opacity: 0;
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars,react/prop-types
function UploadDiv({ onChange, name, value }: any) {
  return null
}

function PDFsField({
  readOnlyMode,
  id,
  name,
  formState,
  required,
  help,
  validate,
  disabled,
  setFormState,
  errors,
  width,
}: {
  readOnlyMode: boolean
  id: string
  name: string
  formState: any
  required: boolean
  help: string
  validate: any
  disabled: boolean
  setFormState: any
  errors: any
  width: any
}) {
  if (readOnlyMode) {
    return <ReadOnlyPDFsField formState={formState} id={id} name={name} width={width} />
  }
  const value = _.get(formState, id, [])

  // Create a reference to the hidden file input element
  const hiddenFileInput: any = React.useRef(null)

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click()
    }
  }

  const fileInput = (
    <HiddenFileInput
      type="file"
      ref={hiddenFileInput}
      name={id}
      accept="application/pdf"
      multiple
      // renderFile={() => {
      //   return <Text>{name}</Text>
      // }}
      onChange={async (event: any) => {
        const fileList = event?.target?.files
        const uploadURLs = await Promise.all(
          Object.values(fileList).map(async (file: any) => {
            const fileBuffer = await file.arrayBuffer()
            const hashBuffer = await crypto.subtle.digest('SHA-1', fileBuffer)
            const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
            const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('') // convert bytes to hex string
            const fileExtension = _.last(file.name.split('.'))
            const fileName = `images/${hashHex}.${fileExtension}`
            const contractRef = ref(storage, fileName)
            // const upload = await uploadBytes(contractRef, file)
            await uploadBytes(contractRef, file)
            const fileURL = `https://storage.googleapis.com/marketplace-353300.appspot.com/${fileName}`
            // const url = getDownloadURL(upload.ref)
            return fileURL
          }),
        )
        hiddenFileInput.current.value = null
        const val = [...(_.get(formState, id) || []), ...uploadURLs]
        const newFormState = _.set(_.cloneDeep(formState), id, val)
        setFormState(newFormState)
      }}
    />
  )

  return (
    <>
      {createPortal(fileInput, document.body)}
      <AutoFormField
        id={id}
        key={id}
        name={name}
        required={required}
        help={help}
        validate={validate}
        disabled={disabled}
        width={width}
        errors={errors}
      >
        <UploadDiv name={name} value={value} onChange={() => null} />
      </AutoFormField>
      <PDFsBox readOnly={readOnlyMode}>
        {_.map(value || [], (fileURL: any, fileURLIndex: number) => {
          return (
            <PDFBox
              key={fileURL}
              round="xsmall"
              pad={{ horizontal: 'small', vertical: 'xsmall' }}
              margin={{ vertical: 'small', left: 'small' }}
              overflow="hidden"
              border={{ color: 'dark-2' }}
              direction="row"
              alignContent="center"
            >
              <Anchor href={fileURL} target="_blank">
                {value.length > 1 ? `${name} ${fileURLIndex + 1}.pdf` : `${name}.pdf`}
              </Anchor>
              <DeletePDFIconBox
                hoverIndicator={{ background: { color: 'light-4' } }}
                margin={{ left: 'xsmall' }}
                onClick={(e) => {
                  e.stopPropagation()
                  const newVal = value.filter((val: any, j: number) => j !== fileURLIndex)
                  const newFormState = _.set(_.cloneDeep(formState), id, newVal)
                  setFormState(newFormState)
                }}
              >
                <FormClose />
              </DeletePDFIconBox>
            </PDFBox>
          )
        })}
        <PDFBox
          key="add-pdf"
          round="xsmall"
          pad={{ horizontal: 'small', vertical: 'xsmall' }}
          margin={{ vertical: 'small', left: 'small' }}
          overflow="hidden"
          border={{ color: 'dark-2' }}
          direction="row"
          alignContent="center"
          onClick={handleClick}
        >
          <Text>Add PDF</Text>
        </PDFBox>
      </PDFsBox>
    </>
  )
}

export default PDFsField
