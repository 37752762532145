import { Anchor, Text } from 'grommet'
import _ from 'lodash'
import React from 'react'
import {
  DropShippingAllowed,
  dropShippingAllowedLabels,
  VendorContractType,
  vendorContractTypeLabels,
  VendorStatus,
  vendorStatusLabels,
  VendorTier,
  vendorTierLabels,
} from '../../../lib/firestore/types/types'
import { mapEnumAndLabelsToOptions } from '../autoform/utils'

const roleNeeded = process.env.REACT_APP_MODE

const brandName = {
  id: 'brandName',
  name: 'Brand Name',
  type: 'text',
  required: true,
}

const id = {
  id: 'id',
  name: 'ID',
  type: 'text',
  disabled: true,
  required: true,
}

const skuPrefix = {
  id: 'skuPrefix',
  name: 'SKU Prefix',
  type: 'text',
  disabled: true,
  required: true,
}

const email = {
  id: 'email',
  name: 'Account Email',
  type: 'email',
  required: true,
}

const uid = {
  id: 'uid',
  name: 'Account UID',
  type: 'text',
  required: true,
  help: (
    <Text weight="lighter" size="xsmall">
      <Anchor
        target="blank"
        href="https://console.firebase.google.com/u/3/project/marketplace-353300/authentication/users"
      >
        Firebase UID of the account associated with this vendor
      </Anchor>
    </Text>
  ),
}

const contract = {
  id: 'contract',
  name: 'Contract',
  type: 'pdfs',
  required: false,
}

const contractAddendum = {
  id: 'contractAddendum',
  name: 'Contract Addendum',
  type: 'pdfs',
  required: false,
}

const contractType = {
  id: 'contractType',
  name: 'Contract Type',
  type: 'checkboxgroup',
  required: true,
  // readOnlyValueFormatter: (val: Array<string>) => {
  //   return val.map(_.startCase)
  // },
  options: mapEnumAndLabelsToOptions(VendorContractType, vendorContractTypeLabels),
}
const commissionPercentage = {
  id: 'commissionPercentage',
  name: 'Commission',
  type: 'number',
  required: true,
  condition: (formState: any) => {
    return formState.contractType.includes(VendorContractType.commission)
  },
  help: <Text>The percentage of the comission that ap0cene receives</Text>,
}

const dropShippingAllowed = {
  id: 'dropShippingAllowed',
  name: 'Drop Shipping',
  type: 'select',
  options: mapEnumAndLabelsToOptions(DropShippingAllowed, dropShippingAllowedLabels),
  placeholder: 'Select',
  required: true,
  help: <Text>Give permission to the Vendor to dropship orders from their location</Text>,
}

const initialPassword = {
  id: 'initialPassword',
  name: 'Initial Password',
  type: 'text',
  required: true,
  help: <Text size="xsmall">Initial password used in the UID creation</Text>,
  disabled: false,
}

const status = {
  id: 'status',
  name: 'Status',
  type: 'select',
  options: mapEnumAndLabelsToOptions(VendorStatus, vendorStatusLabels),
  // readOnlyValueFormatter: (val: string) => _.startCase(val),
  required: true,
}
const tier = {
  id: 'tier',
  name: 'Tier',
  type: 'select',
  options: mapEnumAndLabelsToOptions(VendorTier, vendorTierLabels),
  // readOnlyValueFormatter: (val: string) => _.startCase(val),
  required: true,
}

export const editVendorFormSchema = _.compact([
  brandName,
  id,
  skuPrefix,
  email,
  contract,
  contractAddendum,
  contractType,
  commissionPercentage,
  dropShippingAllowed,
  status,
  roleNeeded === 'admin' ? tier : null,
  uid,
  initialPassword,
])

export const addVendorFormSchema = [
  brandName,
  email,
  contract,
  contractAddendum,
  contractType,
  commissionPercentage,
  dropShippingAllowed,
  uid,
  initialPassword,
]

export const initialVendorValues = {
  brandName: '',
  email: '',
  uid: '',
  contract: [],
  contractType: [],
  commission: 30,
  id: '',
}

// VENDOR PROFILE FIELDS
